
import styled from "styled-components";
import { AvailableSlotsPerCourt } from "./component/AvailableSlotsPerCourt";
import moment from "moment";
import { useState } from "react";
import { Button } from "ui/Button";


const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;


const TopBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const DateTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
`;


export const Reservations = () => {

  const [viewingDate, setViewingDate] = useState<Date>(new Date());
  const onNextDay = () => {
    const date = moment(viewingDate);
    date.add(1, 'day');
    setViewingDate(date.toDate());
  };

  const onPreviousDay = () => {
    const date = moment(viewingDate);
    date.subtract(1, 'day');
    setViewingDate(date.toDate());
  }
  return (
    <MainContainer>
       <TopBar>
          <Button $type="flat" onClick={onPreviousDay}>Atras</Button>
          <DateTitle>{moment().isSame(viewingDate, 'day') ? 'Hoy - ' : ''}{moment(viewingDate).format('dddd DD MMMM YYYY')}</DateTitle>
          <Button $type="flat" onClick={onNextDay}>Adelante</Button>
        </TopBar>
      <AvailableSlotsPerCourt viewingDate={viewingDate} />
    </MainContainer>
  );
};
