import { Court, courtApi } from "api/courtApi";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useEffect, useState } from "react";
import { fetchCourtsAsync } from "store/court/courtSlice";
import { CourtRow } from "./CourtRow";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { StrapiCollection, StrapiId } from "api/strapi.types";
import { EditCourtModal } from "./EditCourtModal";
import useModal from "hooks/useModal";

const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const CourtList = () => {
  const { courts, loading } = useAppSelector((state) => state.court);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isOpen, openModal, closeModal } = useModal();
  const [editingCourt, setEditingCourt] = useState<StrapiCollection<Court>>();

  useEffect(() => {
    dispatch(fetchCourtsAsync());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteCourt = async (id: StrapiId) => {
    await courtApi.delete(id);
    dispatch(fetchCourtsAsync());
  };

  const openSchedule = (courtId: StrapiId) => {
    navigate(`/courts/${courtId}/schedule`);
  };

  const onOpenEvents = (courtId: StrapiId) => {
    navigate(`/courts/${courtId}/events`);
  }

  const onEdit = (courtId: StrapiId) => {
    const court = courts.find((court) => court.id === courtId);
    setEditingCourt(court);
    openModal();
  }

  return (
    <MainContainer>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          {courts.map((court) => (
            <CourtRow
              key={court.id}
              court={court}
              onDelete={deleteCourt}
              onEdit={onEdit}
              openSchedule={openSchedule}
              openEvents={onOpenEvents}
            />
          ))}
        </div>
      )}
      <EditCourtModal editingCourt={editingCourt} isOpen={isOpen} onClose={() => {
        closeModal();
      }} /> 
    </MainContainer>
  );
};
