import Modal from "ui/Modal";
import styled from "styled-components";
import { StrapiCollection, StrapiId } from "api/strapi.types";
import { Button } from "ui/Button";
import {
  CourtReservation,
  CourtReservationStatus,
  courtReservationApi,
} from "api/courtReservationApi";
import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/es";
import { ConfirmActionButton } from "ui/ConfirmActionButton";
moment.locale("es");

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 12px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 0px;
  border-top: 1px solid #e0e0e0;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  max-height: 400px;
  min-width: 450px;
  padding-bottom: 0px;
  flex: 1;
`;

const EventRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 12px;
  padding-top: 12px;
  border-bottom: 1px solid #e0e0e0;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StatusChip = styled.div<{ $status: CourtReservationStatus }>`
  padding: 4px;
  border-radius: 4px;
  with: auto;
  background-color: ${(props) => {
    let backgroundColor;

    switch (props.$status) {
      case CourtReservationStatus.PENDING:
        backgroundColor = "#FFD700";
        break;
      case CourtReservationStatus.CONFIRMED:
      case CourtReservationStatus.STARTED:
      case CourtReservationStatus.COMPLETED:
        backgroundColor = "#00FF00";
        break;
      default:
        backgroundColor = "#FF0000";
        break;
    }
    return backgroundColor;
  }};
`;

export const ViewReservationsModal = ({
  isOpen,
  closeModal,
  courtId,
  viewingDate,
  onStartReservation,
}: {
  isOpen: boolean;
  closeModal: () => void;
  onStartReservation: (courtId: StrapiId, courtReservationId: StrapiId) => void;
  courtId: StrapiId | undefined;
  viewingDate: Date;
}) => {
  const [courtReservations, setCourtReservations] = useState<
    StrapiCollection<CourtReservation>[]
  >([]);

  const getCourtReservations = useCallback(
    async (courtId: StrapiId) => {
      const { data: collection } = await courtReservationApi.getByCourt(
        courtId,
        viewingDate
      );

      setCourtReservations(collection.data);
    },
    [viewingDate]
  );

  useEffect(() => {
    if (courtId && isOpen) {
      getCourtReservations(courtId);
    }
  }, [courtId, getCourtReservations, isOpen]);

  const mapStatusToText = (status: CourtReservationStatus) => {
    switch (status) {
      case CourtReservationStatus.PENDING:
        return "Pendiente";
      case CourtReservationStatus.CONFIRMED:
        return "Confirmado";
      case CourtReservationStatus.CANCELED:
        return "Cancelado";
      case CourtReservationStatus.COMPLETED:
        return "Completado";
      case CourtReservationStatus.STARTED:
        return "Iniciado";
      default:
        return "Desconocido";
    }
  };

  const createCourtTab = (courtId: StrapiId, courtReservationId: StrapiId) => {
    onStartReservation(courtId, courtReservationId);
    closeViewModal();
  };

  const closeViewModal = () => {
    closeModal();
    setCourtReservations([]);
  };

  const cancelReservation = async (courtId: StrapiId) => {
    await courtReservationApi.update(courtId, {
      status: CourtReservationStatus.CANCELED,
    });
    await getCourtReservations(courtId);
  };

  if (!courtId) return null;

  return (
    <Modal isOpen={isOpen}>
      <ModalBody>
        <h2>Reservaciones para: {moment(viewingDate).format("DD MMM YYYY")}</h2>
        {courtReservations.map((reservation) => {
          const client = reservation.attributes.client.data?.attributes;
          const clientName = `${client?.firstName} ${client?.lastName}`;
          return (
            <EventRow key={reservation.id}>
              <Column
                style={{
                  flex: 1,
                }}
              >
                <Row>
                  <strong>Estado:</strong>{" "}
                  <StatusChip $status={reservation.attributes.status}>
                    {mapStatusToText(reservation.attributes.status)}
                  </StatusChip>
                </Row>
                <Row>
                  <strong>Cliente:</strong> <span>{clientName}</span>
                </Row>
                <Row>
                  <strong>Horario:</strong>
                  <span>
                    <span>
                      {moment(reservation.attributes.startTime).format(
                        "HH:mm A"
                      )}
                    </span>{" "}
                    -{" "}
                    <span>
                      {moment(reservation.attributes.endTime).format("HH:mm A")}
                    </span>
                  </span>
                </Row>
              </Column>
              {reservation.attributes.status === CourtReservationStatus.PENDING && (
                <Column
                  style={{
                    gap: 10,
                    padding: 12,
                    borderLeft: "1px solid #e0e0e0",
                    marginLeft: 14,
                  }}
                >
                  <Button
                    onClick={() => {
                      createCourtTab(courtId, reservation.id);
                    }}
                    $type="primary"
                  >
                    Iniciar
                  </Button>
                  <ConfirmActionButton
                    $message="Estas seguro que deseas cancelar la reservacion?"
                    onClick={() => {
                      cancelReservation(reservation.id);
                    }}
                    $type="danger"
                  >
                    Cancelar
                  </ConfirmActionButton>
                </Column>
              )}
            </EventRow>
          );
        })}
      </ModalBody>
      <Footer>
        <Button $type="flat" onClick={closeViewModal}>
          Cerrar
        </Button>
      </Footer>
    </Modal>
  );
};
