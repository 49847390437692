import { backendClient } from "./backendClient";
import { StrapiDefaultAttributes, StrapiEntries, StrapiEntry, StrapiId, StrapiMedia, StrapiSingleRelation } from "./strapi.types";


type CreateInventoryDto = {
  name: string;
  description: string;
  price: number;
  qty: number;
}


export interface Inventory extends StrapiDefaultAttributes {
  name: string;
  description: string;
  image: StrapiSingleRelation<StrapiMedia>;
  price: number;
  qty: number;
}


export const inventoryApi = {
  create: (payload: CreateInventoryDto) => {
    return backendClient.post("/api/inventories/custom", {
      data : payload
    });
  },
  delete: (id: StrapiId) => {
    return backendClient.delete(`/api/inventories/${id}`);
  },
  get: () => {
    return backendClient.get<StrapiEntries<Inventory>>("/api/inventories");
  },
  getOne: (id: StrapiId) => {
    return backendClient.get<StrapiEntry<Inventory>>(`/api/inventories/${id}?populate=*`);
  },
  update: (id: StrapiId, payload: CreateInventoryDto) => {
    return backendClient.put(`/api/inventories/custom/${id}`, {
      data: payload
    });
  }
}