import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { StrapiCollection } from 'api/strapi.types';
import { Racket, racketApi } from 'api/racketApi';


type InitialState = {
  rackets: StrapiCollection<Racket>[];
  loading: boolean;
  error: string | undefined;
};

// Define the initial state
const initialState: InitialState = {
  rackets: [],
  loading: false,
  error: undefined,
};

// Define the async thunk to fetch rackets
export const fetchRacketsAsync = createAsyncThunk(
  'racket/fetchRackets',
  async () => {
    try {
      const response = await racketApi.get();
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch courts');
    }
  }
);

// Create the racketSlice
const racketSlice = createSlice({
  name: 'racket',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRacketsAsync.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(fetchRacketsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.rackets = action.payload.data;
      })
      .addCase(fetchRacketsAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default racketSlice;