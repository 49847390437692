import { Court } from "api/courtApi";
import { CourtTab } from "api/courtTabApi";
import { StrapiCollection, StrapiId } from "api/strapi.types";
import styled from "styled-components";
import { ButtonFlat } from "ui/Button";
import { ColorPalette } from "ui/Color";
import { CourtTabInfo } from "./CourtTabInfo";


type CourtCardProps = {
  court: StrapiCollection<Court>;
  courtTab?: StrapiCollection<CourtTab>;
  onOpenTab: (courtId: StrapiId) => void;
  onViewReservation: (courtId: StrapiId) => void;
  onStopTab: (courtId: StrapiId) => void;
  viewingDate: Date;
  onAddInventoryToTab: (courtTabId: StrapiId) => void;
  onAddRacketToTab: (courtTabId: StrapiId) => void;
};

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid ${ColorPalette.lightGray};
  border-radius: 4px;
  margin: 8px;
  min-height: 300px;
  box-shadow: 6px 4px 16px 0px rgba(0, 0, 0, 0.11);
  -webkit-box-shadow: 6px 4px 16px 0px rgba(0, 0, 0, 0.11);
  -moz-box-shadow: 6px 4px 16px 0px rgba(0, 0, 0, 0.11);
`;

const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  gap: 8px;
  border-right: 1px solid ${ColorPalette.lightGray};
  box-shadow: 5px 0px 15px 0px rgba(0, 0, 0, 0.11);
  -webkit-box-shadow: 5px 0px 15px 0px rgba(0, 0, 0, 0.11);
  -moz-box-shadow: 5px 0px 15px 0px rgba(0, 0, 0, 0.11);
`;

const ClientName = styled.span`
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-left: 12px;
  margin-right: 12px;
`;

const CourtName = styled.span`
  font-size: 18px;
  font-weight: 600;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid ${ColorPalette.lightGray};
  width: 100%;
  text-align: center;
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px;
  flex: 1;
`;

const TopPart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const CourtCard = (props: CourtCardProps) => {
  const {
    court,
    onOpenTab,
    onViewReservation,
    onStopTab,
    courtTab,
    onAddInventoryToTab,
    onAddRacketToTab,
  } = props;

  let CourtOption = (
    <ButtonFlat
      onClick={() => {
        onOpenTab(court.id);
      }}
    >
      Iniciar Cuenta
    </ButtonFlat>
  );

  if (courtTab) {
    CourtOption = (
      <ButtonFlat
        $type="danger"
        onClick={() => {
          onStopTab(courtTab.id);
        }}
      >
        Detener Cuenta
      </ButtonFlat>
    );
  }

  const client = courtTab?.attributes.client.data?.attributes;
  const clientName = client?.firstName + " " + client?.lastName;

  return (
    <CardContainer>
      <CardHeader>
        <CourtName>{props.court.attributes.name}</CourtName>
        <TopPart>
          {client && <ClientName>{clientName}</ClientName>}
        </TopPart>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            justifyContent: "flex-end",
            gap: 6,
            width: "100%",
            padding: 6,
          }}
        >
          <ButtonFlat
            $type="flat"
            width="100%"
            onClick={() => {
              onViewReservation(court.id);
            }}
          >
            Ver Reservaciones
          </ButtonFlat>
          {CourtOption}
        </div>
      </CardHeader>
      <CardBody>
        {courtTab && (
          <CourtTabInfo
            courtTab={courtTab}
            onAddInventoryToTab={onAddInventoryToTab}
            onAddRacketToTab={onAddRacketToTab}
          />
        )}
      </CardBody>
    </CardContainer>
  );
};
