import { Client, clientApi } from "api/clientApi";
import { StrapiEntry } from "api/strapi.types";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { ActivityLoader } from "ui/ActivityLoader";
import { Breadcrumb } from "ui/Breadcrumb";
import { Button } from "ui/Button";
import { Image } from "ui/Image";
import { InputWithLabel } from "ui/InputWithLabel";
import { PageTopSection } from "ui/PageTopSection";
import { PageTopSectionTitle } from "ui/PageTopSectionTitle";
import * as Yup from "yup";

const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const EditUserContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 16px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-direction: column;
  background-color: #ffffff;
  padding: 16px;
  width: auto;
  border-radius: 8px;
  min-width: 300px;
  box-shadow: 1px 1px 2px 2px #0000000a;
`;

const ErrorLabel = styled.label`
  color: red;
  font-size: 12px;
`;

const Avatar = styled(Image)`
  width: 100px;
  height: 100px;
  align-self: center;
  border-radius: 50%;
  object-fit: cover;
  background-color: #e0e0e0;
`;


const editUserValidationSchema = Yup.object().shape({
  phone: Yup.string().required("Telefono es requerido"),
  firstName: Yup.string().required("Nombre es requerido"),
  lastName: Yup.string().required("Apellido es requerido"),
});

export const EditClient = () => {
  const { clientId } = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [userDetails, setUserDetails] = useState<StrapiEntry<Client>>();
  const [newAvatarFile, setNewAvatarFile] = useState<File>();

  const fetchUserDetails = async () => {
    if (!clientId) {
      setLoading(false);
      return;
    }

    const { data: adminDetails } = await clientApi.getById(Number(clientId));
    setLoading(false);
    setUserDetails(adminDetails);
  };

  const editUser = async (
    firstName: string | undefined,
    lastName: string | undefined,
    phone: string | undefined
  ) => {
    await clientApi.edit(Number(clientId), {
      firstName,
      lastName,
      phone
    });
    fetchUserDetails();
  };

  const onSubmit = (values: {
    email?: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
  }) => {
    // if (values.password) {
    //   changePassword(values.password);
    // }

    editUser(values.firstName, values.lastName, values.phone);
  };
  const handleFileChange = async (e: any) => {
    const file = e.target.files[0];

    setNewAvatarFile(file);
  };

  const uploadAvatar = async () => {
    if (!newAvatarFile || !userDetails) {
      alert("Foto invalida");
      return;
    }
    // const response = await clientApi.uploadAvatar(formData);
    await clientApi.uploadAvatar(userDetails.data.id, newAvatarFile);
    setNewAvatarFile(undefined);
    fetchUserDetails();
  };

  useEffect(() => {
    fetchUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userName =
    userDetails?.data.attributes.firstName +
    " " +
    userDetails?.data.attributes.lastName;

  return (
    <MainContainer>
      <PageTopSection>
        <PageTopSectionTitle>{userName}</PageTopSectionTitle>
        <Breadcrumb
          path={[
            {
              label: "Clientes",
              selected: false,
              path: "/clients",
            },
            {
              label: userName,
              selected: true,
            },
          ]}
        />
      </PageTopSection>
      <EditUserContainer>
        <FormContainer>
          <h2>Editar Usuario</h2>
          <Avatar src={newAvatarFile ? URL.createObjectURL(newAvatarFile) : userDetails?.data.attributes.avatar.data?.attributes.formats.small.url} />
          <input type="file" accept="image/*" onChange={handleFileChange} />
          <Button disabled={!newAvatarFile} $type="secondary" width="auto" onClick={uploadAvatar}>Cambiar Foto</Button>
          {loading ? (
            <ActivityLoader />
          ) : (
            <Formik
              initialValues={{
                email: userDetails?.data.attributes.user.data?.attributes.email,
                firstName: userDetails?.data.attributes.firstName,
                lastName: userDetails?.data.attributes.lastName,
                phone: userDetails?.data.attributes.phone ?? '',
              }}
              validationSchema={editUserValidationSchema}
              onSubmit={onSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => {
                return (
                  <>
                    <InputWithLabel
                      disabled
                      label="Email"
                      placeholder="Email"
                      name="email"
                      value={values.email}
                    />
                    <InputWithLabel
                      label="Nombre"
                      placeholder="Nombre"
                      name="firstName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.firstName}
                    />
                    {errors.firstName && touched.firstName && (
                      <ErrorLabel>{errors.firstName}</ErrorLabel>
                    )}
                    <InputWithLabel
                      label="Apellido"
                      placeholder="Apellido"
                      name="lastName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.lastName}
                    />
                    {errors.lastName && touched.lastName && (
                      <ErrorLabel>{errors.lastName}</ErrorLabel>
                    )}
                    <InputWithLabel
                      label="Telefono"
                      placeholder="Telefono"
                      name="phone"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.phone}
                    />
                    {errors.phone && touched.phone && (
                      <ErrorLabel>{errors.phone}</ErrorLabel>
                    )}
                    {/* <InputWithLabel
                    type="password"
                      label="Contraseña"
                      placeholder="Contraseña"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                    />
                    {errors.password && touched.password && (
                      <ErrorLabel>{errors.password}</ErrorLabel>
                    )} */}
                    <Button
                      type="submit"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Guardar
                    </Button>
                  </>
                );
              }}
            </Formik>
          )}
        </FormContainer>
      </EditUserContainer>
    </MainContainer>
  );
};
