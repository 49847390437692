import styled from "styled-components";
import { ColorPalette } from "./Color";

export const CheckBox = styled.input.attrs({ type: "checkbox" })<{
  $big?: boolean;
}>`
  appearance: none;
  width: ${({ $big }) => ($big ? "24px" : "16px")};
  height: ${({ $big }) => ($big ? "24px" : "16px")};
  border-radius: ${({ $big }) => ($big ? "4px" : "2px")};
  border: 1px solid #ccc;
  outline: none;
  cursor: pointer;

  &:checked {

    background-color: ${ColorPalette.mintGreen};
  }
`;