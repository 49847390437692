import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CourtTab, courtTabApi } from 'api/courtTabApi';

import { StrapiCollection } from 'api/strapi.types';



type InitialState = {
  courtTabs: StrapiCollection<CourtTab>[];
  loading: boolean;
  error: string | undefined;
};

// Define the initial state
const initialState: InitialState = {
  courtTabs: [],
  loading: false,
  error: undefined,
};

export const fetchCourtTabAsync = createAsyncThunk(
  'courtTab/fetchCourtTabs',
  async () => {
    try {
      const response = await courtTabApi.getOpenTabs();
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch court tabs');
    }
  }
);

// Create the courtTabSlice
const courtTabSlice = createSlice({
  name: 'courtTab',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCourtTabAsync.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(fetchCourtTabAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.courtTabs = action.payload.data;
      })
      .addCase(fetchCourtTabAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default courtTabSlice;