import { HTMLAttributes, cloneElement } from "react";
import { Button, ButtonFlat } from "./Button";

interface ConfirmActionButtonProps extends HTMLAttributes<HTMLButtonElement> {
  $message: string;
  $type: "primary" | "secondary" | "flat" | "danger";
}

interface ConfirmActionProps {
    $message: string;
    onClick: (e: any) => void;
    children: JSX.Element;
    
}

export const ConfirmActionButton = (props: ConfirmActionButtonProps) => {
    const handleConfirmClick = (e: any) => {
        if (window.confirm(props.$message)) {
            props.onClick?.(e);
        }
    };
    return (
        <Button {...props} onClick={handleConfirmClick} >{props.children}</Button>
    );
}
export const ConfirmActionButtonFlat = (props: ConfirmActionButtonProps) => {
    const handleConfirmClick = (e: any) => {
        if (window.confirm(props.$message)) {
            props.onClick?.(e);
        }
    };
    return (
        <ButtonFlat {...props} onClick={handleConfirmClick} >{props.children}</ButtonFlat>
    );
}

export const ConfirmActionWrapper = (props: ConfirmActionProps) => {
    const handleConfirmClick = (e: any) => {
        if (window.confirm(props.$message)) {
            props.onClick?.(e);
        }
    };
    const clonedElement = cloneElement(props.children, { onClick: handleConfirmClick });
    return clonedElement;
}