import { backendClient } from "./backendClient";
import { StrapiDefaultAttributes, StrapiId } from "./strapi.types";


type CreateUserDto = {
  email: string;
  username: string;
  password: string;
  role: Roles;
  confirmed: boolean;
}

export enum Roles {
  Admin = 3,
  Client = 4
}

export interface User extends StrapiDefaultAttributes {
  username: string;
  email: string;
  provider: string;
  confirmed: boolean;
  blocked: boolean;
}

export interface LoginResponse {
  jwt: string;
  user: User;
}


export const userApi = {
  createUser: (payload: CreateUserDto) => {
    return backendClient.post("/api/users", payload);
  },
  deleteUser: (id: StrapiId) => {
    return backendClient.delete(`/api/users/${id}`);
  },
  changePassword: (userId: StrapiId, password: string) => {
    return backendClient.put(`/api/users/${userId}`, {
      data : {
        password
      }
    })
  },
  me: () => {
    return backendClient.get<User>("/api/users/me");
  },
  login: (email: string, password: string) => {
    return backendClient.post<LoginResponse>("/api/auth/local", {
      identifier: email,
      password
    });
  },
}