import { combineReducers, configureStore } from '@reduxjs/toolkit'
import authSlice from './auth/authSlice'
import adminSlice from './admin/adminSlice'
import courtSlice from './court/courtSlice'
import courtScheduleSlice from './courtSchedule/courtScheduleSlice';
import courtEventSlice from './courtEvent/courtEventSlice';
import inventorySlice from './inventory/inventorySlice';
import racketSlice from './racket/racketSlice';
import clientSlice from './client/clientSlice';
import reservationSlice from './reservation/reservationSlice';
import courtTabSlice from './courtTab/courtTabSlice';
import priceConfigSlice from './priceConfig/priceConfigSlice';
import racketLeaseSlice from './racketLease/racketLeaseSlice';
import inventorySaleSlice from './inventorySale/inventorySaleSlice';

const rootReducer = combineReducers({
  auth: authSlice.reducer,
  admin: adminSlice.reducer,
  client: clientSlice.reducer,
  court: courtSlice.reducer,
  courtTab: courtTabSlice.reducer,
  courtSchedule: courtScheduleSlice.reducer,
  courtEvent: courtEventSlice.reducer,
  inventory: inventorySlice.reducer,
  racket: racketSlice.reducer,
  reservation: reservationSlice.reducer,
  priceConfig: priceConfigSlice.reducer,
  racketLease: racketLeaseSlice.reducer,
  inventorySale: inventorySaleSlice.reducer,
})
export type RootState = ReturnType<typeof rootReducer>

export const store = configureStore({
  reducer: rootReducer,
})

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

