import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { ColorPalette } from './Color';

// Define the props type for the Select component
type SelectProps = InputHTMLAttributes<HTMLSelectElement> & {
    options: { value: string | number; label: string }[];
    defaultValue?: string;
};

// Create a styled select element
const StyledSelect = styled.select`
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    font-size: 16px;
    color: #333;
    margin-top: 6px;
    margin-bottom: 6px;
    &:focus {
        outline: none;
        border-color: ${ColorPalette.limeGreen};
    }
`;

// The Select component using styled-components
const Select: React.FC<SelectProps> = ({ name, options, value, onChange, ...rest }) => {
    return (
        <StyledSelect name={name} value={value} onChange={onChange} {...rest}>
            {options.map(option => (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            ))}
        </StyledSelect>
    );
};

export default Select;