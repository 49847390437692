import { backendClient } from "./backendClient";
import { StrapiMedia, StrapiEntries, StrapiEntry, StrapiSingleRelation, StrapiId } from "./strapi.types";
import { User } from "./userApi";

interface CreateAdminDto {
  firstName: string;
  lastName: string;
  user: number;
}

interface UpdateParams {
  firstName?: string;
  lastName?: string;
}

export enum AdminRoles {
  Admin = 'admin',
  Regular = 'regular'
}

export type Admin = {
  firstName: string;
  lastName: string;
  avatar: StrapiSingleRelation<StrapiMedia>;
  user: StrapiSingleRelation<User>;
  role: AdminRoles;
}

export const adminApi = {
  getAdmins: async () => {
     return await backendClient.get<StrapiEntries<Admin>>("/api/admins?populate=*");
  },
  getAdminById: async (adminId: StrapiId) => {
    return await backendClient.get<StrapiEntry<Admin>>(`/api/admins/${adminId}?populate=*`);
  },
  searchAdminByUserId: async (userId: StrapiId) => {
    return await backendClient.get<StrapiEntries<Admin>>(`/api/admins?filters[user][$eq]=${userId}&populate=*`);
  },
  editAdminById: async (userId: StrapiId, payload: UpdateParams) => {
    return await backendClient.put(`/api/admins/${userId}`, {
      data: payload
    })
  },
  createAdmin: (payload: CreateAdminDto) => {
    return backendClient.post("/api/admins", {
      data: payload
    });
  },
  deleteAdmin: (id: StrapiId) => {
    return backendClient.delete(`/api/admins/${id}`);
  },
}