import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import styled from "styled-components";
import { CourtCard } from "./CourtCard";
import { useEffect, useState } from "react";
import { fetchCourtsAsync } from "store/court/courtSlice";
import { ColorPalette } from "ui/Color";
import { StrapiId } from "api/strapi.types";
import useModal from "hooks/useModal";
import { ViewReservationsModal } from "./ViewReservationsModal";
import moment from "moment";
import { StartCourtTabModal } from "./StartCourtTabModal";
import { fetchCourtTabAsync } from "store/courtTab/courtTabSlice";
import { fetchCourtSchedulesAsync } from "store/courtSchedule/courtScheduleSlice";
import { AddInventoryToTabModal } from "./AddInventoryToTabModal";
import { AddRacketToTabModal } from "./AddRacketToTabModal";
import { fetchOpenTabInventorySalesAsync } from "store/inventorySale/inventorySaleSlice";
import { fetchOpenTabRacketSalesAsync } from "store/racketLease/racketLeaseSlice";
import { StopTabModal } from "./StopTabModal";

const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${ColorPalette.white};
  border-radius: 8px;
  padding: 16px;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
`;

const TopBarDateNavigator = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const DateTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
`;

export const CourtGrid = () => {
  const courts = useAppSelector((state) => state.court.courts);
  const courtTabs = useAppSelector((state) => state.courtTab.courtTabs);

  const dispatch = useAppDispatch();
  const {
    isOpen: isOpenViewReservation,
    closeModal: closeViewReservationModal,
    openModal: openViewReservationModal,
  } = useModal();
  const {
    isOpen: isOpenCreateCourtTab,
    closeModal: closeCreateCourtTabModal,
    openModal: openCreateCourTabModal,
  } = useModal();
  const [viewingCourtId, setViewingCourtId] = useState<StrapiId>();
  const [viewingCourtTabId, setViewingCourtTabId] = useState<StrapiId>();
  const {
    isOpen: isOpenAddInventoryToTab,
    closeModal: closeAddInventoryToTabModal,
    openModal: openAddItemsToTabModal,
  } = useModal();
  const {
    isOpen: isOpenAddRacketToTab,
    closeModal: closeAddRacketToTabModal,
    openModal: openAddRacketToTabModal,
  } = useModal();

  const {
    isOpen: isOpenStopTabModal,
    closeModal: closeStopTabModal,
    openModal: openStopTabModal,
  } = useModal();

  const [viewingDate] = useState<Date>(new Date());
  const [startCourtReservationId, setStartCourtReservationId] =
    useState<StrapiId>();

  useEffect(() => {
    dispatch(fetchCourtsAsync());
    dispatch(fetchCourtTabAsync());
    dispatch(fetchCourtSchedulesAsync());
    // Find all open tabs
    dispatch(fetchOpenTabRacketSalesAsync());
    dispatch(fetchOpenTabInventorySalesAsync());
  }, [dispatch, viewingDate]);

  const startTab = (courtId: StrapiId) => {
    setViewingCourtId(courtId);
    openCreateCourTabModal();
  };

  const viewReservation = (courtId: StrapiId) => {
    setViewingCourtId(courtId);
    openViewReservationModal();
  };

  const onStopTab = async (courtTabId: StrapiId) => {
    setViewingCourtTabId(courtTabId);
    openStopTabModal();
  };

  const startReservation = (
    courtId: StrapiId,
    courtReservationId: StrapiId
  ) => {
    setStartCourtReservationId(courtReservationId);
    setViewingCourtId(courtId);
    openCreateCourTabModal();
  };

  const closeCreateCourtTab = () => {
    closeCreateCourtTabModal();
    setStartCourtReservationId(undefined);
  };

  const onAddInventoryToTab = (courtTabId: StrapiId) => {
    setViewingCourtTabId(courtTabId);
    openAddItemsToTabModal();
  };
  const onAddRacketToTab = (courtTabId: StrapiId) => {
    setViewingCourtTabId(courtTabId);
    openAddRacketToTabModal();
  };

  return (
    <MainContainer>
      <TopBarDateNavigator>
        <DateTitle>{moment(viewingDate).format("DD MMM YYYYY")}</DateTitle>
      </TopBarDateNavigator>
      <GridContainer>
        {courts.map((court) => {
          const courtTab = courtTabs.find(
            (tab) => tab.attributes.court.data?.id === court.id
          );
          return (
            <CourtCard
              viewingDate={viewingDate}
              onViewReservation={viewReservation}
              onStopTab={onStopTab}
              onOpenTab={startTab}
              onAddInventoryToTab={onAddInventoryToTab}
              onAddRacketToTab={onAddRacketToTab}
              courtTab={courtTab}
              key={court.id}
              court={court}
            />
          );
        })}
      </GridContainer>
      <ViewReservationsModal
        onStartReservation={startReservation}
        viewingDate={viewingDate}
        isOpen={isOpenViewReservation}
        closeModal={closeViewReservationModal}
        courtId={viewingCourtId}
      />
      <StartCourtTabModal
        courtReservationId={startCourtReservationId}
        closeModal={closeCreateCourtTab}
        courtId={viewingCourtId}
        isOpen={isOpenCreateCourtTab}
        viewingDate={viewingDate}
      />
      <AddInventoryToTabModal
        isOpen={isOpenAddInventoryToTab}
        closeModal={closeAddInventoryToTabModal}
        courtTabId={viewingCourtTabId}
      />
      <AddRacketToTabModal
        isOpen={isOpenAddRacketToTab}
        closeModal={closeAddRacketToTabModal}
        courtTabId={viewingCourtTabId}
      />
      <StopTabModal
        closeModal={()=> {
          closeStopTabModal();
          setViewingCourtTabId(undefined);
        }}
        isOpen={isOpenStopTabModal}
        courtTabId={viewingCourtTabId}
      />
     
    </MainContainer>
  );
};
