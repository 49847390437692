import { Court, courtApi } from "api/courtApi";
import { StrapiCollection, StrapiId } from "api/strapi.types";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { SelectScheduleRow } from "./SelectScheduleRow";
import { fetchCourtSchedulesAsync } from "store/courtSchedule/courtScheduleSlice";
import { Breadcrumb } from "ui/Breadcrumb";
import { toast } from "react-toastify";

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CourtScheduleAssigment = () => {
  const params = useParams<{ courtId: string }>();

  const courtSchedule = useAppSelector((state) => state.courtSchedule);
  const dispatch = useAppDispatch();
  const [courtDetails, setCourtDetails] = useState<StrapiCollection<Court>>();
  // const notify = () => toast("Wow so easy!");

  const fetchCourtDetails = async (courtId: string) => {
    const { data } = await courtApi.getOne(parseInt(courtId));
    setCourtDetails(data.data);
  };

  const refreshCourtDetails = useCallback(async () => {
    if (params.courtId) {
      await fetchCourtDetails(params.courtId);
    }
  }, [params.courtId]);

  useEffect(() => {
    if (params.courtId) {
      refreshCourtDetails();
    }
  }, [params.courtId, refreshCourtDetails]);

  useEffect(() => {
    dispatch(fetchCourtSchedulesAsync());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelected = async (courtScheduleId: StrapiId) => {
    const courSchedules =
      courtDetails?.attributes.courtSchedules?.data?.map(
        (courtSchedule) => courtSchedule!.id
      ) || [];
    toast.promise(
      async () => {
        await courtApi.updateCourtSchedule(
          courtDetails!.id,
          courSchedules.concat(courtScheduleId)
        );
        refreshCourtDetails();
      },
      {
        error: "Error al assignar el horario",
        success: "Horario assignado correctamente",
      }
    );
  };

  const onUnSelected = async (courtScheduleId: StrapiId) => {
    console.log(courtScheduleId);
    const courSchedules =
      courtDetails?.attributes.courtSchedules?.data?.map(
        (courtSchedule) => courtSchedule.id
      ) || [];
    // Remove unselected schedule from the list
    const updatedSchedules = courSchedules.filter(
      (id) => id !== courtScheduleId
    );
    toast.promise(
      async () => {
        await courtApi.updateCourtSchedule(courtDetails!.id, updatedSchedules);
        refreshCourtDetails();
      },
      {
        error: "Error al desassignar el horario",
        success: "Horario desassignado correctamente",
      }
    );
  };

  return (
    <div>
      <Row
        style={{
          marginBottom: 20,
          borderBottom: "1px solid #e0e0e0",
          paddingBottom: 12,
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
          }}
        >
          <span
            style={{
              fontSize: 24,
              fontWeight: 600,
            }}
          >
            Horarios Cancha {courtDetails?.attributes?.name}
          </span>
        </div>

        <Breadcrumb
          path={[
            {
              label: "Canchas",
              selected: false,
              path: "/courts",
            },
            {
              label: courtDetails?.attributes?.name || "",
              selected: true,
            },
          ]}
        />
      </Row>
      <Column>
        {courtSchedule.courtSchedules.map((courtSchedule) => {
          return (
            <SelectScheduleRow
              isChecked={
                courtDetails?.attributes.courtSchedules?.data?.some(
                  (cs) => cs.id === courtSchedule.id
                ) || false
              }
              key={courtSchedule.id}
              courtSchedule={courtSchedule}
              onSelected={onSelected}
              onUnSelected={onUnSelected}
            />
          );
        })}
      </Column>
    </div>
  );
};
