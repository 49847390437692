import styled from "styled-components";
import PadelProLogo from "../../assets/padel-pro-logo-small.png";
import { Link, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { resetAction } from "store/resetAction";
import { auth } from "auth/auth";
import { ConfirmActionButton } from "ui/ConfirmActionButton";
import { AdminRoles } from "api/adminApi";
import { useEffect } from "react";

const SideBarContainer = styled.div`
  padding: 6px;
  height: 100vh;
  background-color: #ffffff;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  z-index: 100;
  min-width: 200px;
  box-shadow: 0px 0px 2px 0 rgba(0, 0, 0, 0.1);
`;

const SideBarItem = styled(Link)`
  padding: 10px;
  cursor: pointer;
  text-decoration: none;
  color: #3c3c3c;
  &:hover {
    background-color: #e0e0e0;
    font-weight: bold;
  }
`;

const ActiveSideBarItem = styled(SideBarItem)`
  font-weight: bold;
`;

const TopLogo = styled.img`
  width: 80px;
  padding: 10px;
  align-self: center;
`;

const Routes = [
  {
    path: "/",
    name: "Dashboard",
    allowedRoles: [AdminRoles.Admin],
  },
  {
    path: "/reservations",
    name: "Reservaciones",
  },
  {
    path: "/court-lease",
    name: "Renta de Canchas",
  },
  {
    path: "/inventory",
    name: "Inventario",
  },
  {
    path: "/rackets",
    name: "Palas",
  },

  {
    path: "/courts",
    name: "Canchas",
  },
  {
    path: "/clients",
    name: "Clientes",
  },
  {
    path: "/users",
    name: "Usuarios",
    // allowedRoles: [AdminRoles.Admin],
  },
  {
    path: "/logout",
    name: "Cerrar Sesion",
  },
];

export const SideBar = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const admin = useAppSelector((state) => state.admin.currentAdmin);

  useEffect(() => {
    
    Routes.forEach((route) => {
      if (route.allowedRoles && route.path === location.pathname && admin) {
        if (!route.allowedRoles?.includes(admin.attributes.role)) {
          window.location.href = "/reservations"
        }
      }
    });
  }, [location, admin]);

  const allowedRoutes = Routes.filter((route) => {
    if (!route.allowedRoles) {
      return true;
    }

    if (!admin) {
      return false;
    }
    return route.allowedRoles.includes(admin.attributes.role);
  });
  return (
    <SideBarContainer>
      <TopLogo src={PadelProLogo} />
      {allowedRoutes.map((route) => {
        if (route.path === "/logout")
          return (
            <ConfirmActionButton
              key={route.path}
              $message="Deseas cerrar sesion?"
              $type="danger"
              onClick={() => {
                dispatch(resetAction());
                auth.logout();
                window.location.href = "/login";
              }}
            >
              {route.name}
            </ConfirmActionButton>
          );

        const isActiveRoute =
          route.path.length === 1
            ? route.path === location.pathname
            : location.pathname.indexOf(route.path) !== -1;
        const LinkComponent = isActiveRoute ? ActiveSideBarItem : SideBarItem;

        return (
          <LinkComponent to={route.path} key={route.path}>
            {route.name}
          </LinkComponent>
        );
      })}
    </SideBarContainer>
  );
};
