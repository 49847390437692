import styled from "styled-components";
import { useEffect } from "react";
import { UserRow } from "./UserRow";
import { fetchAdminsAsync } from "store/admin/adminSlice";
import { useAppDispatch } from "hooks/reduxHooks";
import { useSelector } from "react-redux";
import { RootState } from "store/index";
import { userApi } from "api/userApi";
import { adminApi } from "api/adminApi";
import { useNavigate } from "react-router-dom";
import { auth } from "auth/auth";
import { StrapiId } from "api/strapi.types";

const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #fff;
  margin-top: 20px;
  border-radius: 8px;
`;

const TopHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px;
  padding-left: 24px;
  padding-right: 24px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
`;

const SearchInput = styled.input`
  padding: 12px;
  border: none;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
`;

const NoUsersView = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const UserList = () => {
  const admin = useSelector((state: RootState) => state.admin);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const authUser = auth.getUser();
  const fetchUsers = async () => {
    dispatch(fetchAdminsAsync());
  };
  useEffect(() => {
    fetchUsers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDelete = async (id: StrapiId) => {
    const adminUser = admin.admins.find((user) => user.id === id);
    if(!window.confirm('¿Estás seguro que deseas eliminar este usuario?')){
      return;
    }
    if(!adminUser) {
      alert('Usuario no encontrado');
      return;
    }
    
    await adminApi.deleteAdmin(adminUser.id);
    
    if(adminUser.attributes.user.data?.id){
      await userApi.deleteUser(adminUser.attributes.user.data?.id);
    }
    await fetchUsers();
  }

  const onEdit = async (id: StrapiId) => {
    navigate(`/users/${id}`);
  }

  const adminUsers = admin.admins.filter((admin) => admin.attributes.user.data?.id !== authUser?.id);

  return (
    <MainContainer>
      <TopHeader>
        <span>Lista</span>
        <SearchInput placeholder="Buscar usuario" />
      </TopHeader>

      {adminUsers.length === 0 && (
        <NoUsersView>
          <h1>No hay usuarios</h1>
        </NoUsersView>
      )}

      {adminUsers.map((user) => {
        return <UserRow key={user.id} user={user} onDelete={onDelete} onEdit={onEdit} />;
      })}
    </MainContainer>
  );
};
