import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Admin, adminApi } from '../../api/adminApi';
import { StrapiCollection } from 'api/strapi.types';


type InitialState = {
  admins: StrapiCollection<Admin>[];
  currentAdmin: StrapiCollection<Admin> | undefined;
  loading: boolean;
  error: string | undefined;
};

// Define the initial state
const initialState: InitialState = {
  admins: [],
  currentAdmin: undefined,
  loading: false,
  error: undefined,
};

// Define the async thunk to fetch admins
export const fetchAdminsAsync = createAsyncThunk(
  'admin/fetchAdmins',
  async () => {
    try {
      const response = await adminApi.getAdmins();
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch admins');
    }
  }
);

// Create the adminSlice
const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setAdmin: (state, action: PayloadAction<StrapiCollection<Admin>>) => {
      state.currentAdmin = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminsAsync.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(fetchAdminsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.admins = action.payload.data;
      })
      .addCase(fetchAdminsAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default adminSlice;