import { Racket } from "api/racketApi";
import { StrapiCollection, StrapiId } from "api/strapi.types";
import styled from "styled-components";
import { Button } from "ui/Button";
import { ColorPalette } from "ui/Color";
import { ConfirmActionButton } from "ui/ConfirmActionButton";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 12px;
  border-bottom: 1px solid #e0e0e0;
  &:hover {
    background-color: #f5f5f5;
  }
`;

const Label = styled.span`
  font-size: 16px;
  font-weight: 500;
  flex-drection: column;
`;
const SubLabel = styled.span`
  font-size: 12px;
  font-weight: 400;
  flex-drection: column;
`;

const VerticalDivider = styled.div`
  width: 1px;
  height: 30px;
  background-color: #e0e0e0;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Flex = styled.div`
  display: flex;
  flex: 1;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
`;

const PriceTag = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: #000;
  background-color: ${ColorPalette.limeGreen};
  padding: 6px;
  border-radius: 4px;
`;

export const RacketRow = ({
  racket,
  onDelete,
  onEdit,
}: {
  racket: StrapiCollection<Racket>;
  onDelete: (id: StrapiId) => void;
  onEdit: (id: StrapiId) => void;
}) => {
  return (
    <Row>
      <Flex>
        <Column>
          <PriceTag>${racket.attributes.price}</PriceTag>
        </Column>
        <Column style={{
          marginLeft: 12,
          marginRight: 12
        }}>
          <VerticalDivider />
        </Column>
        <Column style={{
          marginLeft: 12,
          marginRight: 12
        }}>
          <Label>{racket.attributes.name}</Label>
          <SubLabel>{racket.attributes.description}</SubLabel>
        </Column>
      </Flex>
      <ButtonContainer>
        <Button onClick={() => onEdit(racket.id)}>Editar</Button>
        <VerticalDivider />
        <ConfirmActionButton $message="Estas seguro que deseas borrar la pala" $type="danger" onClick={() => onDelete(racket.id)}>
          Borrar
        </ConfirmActionButton>
      </ButtonContainer>
    </Row>
  );
};
