import { CourtSchedule } from "api/courtScheduleApi";
import { StrapiCollection, StrapiId } from "api/strapi.types";
import moment from "moment";
import styled from "styled-components";
import { CheckBox } from "ui/Checkbox";
import { CheckboxWithLabel } from "ui/CheckboxWithLabel";
import { DayAttributes } from "../CourtSchedule/CourtScheduleRow";

const ScheduleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  padding-left: 14px;
  padding-right: 14px;
  margin-bottom: 12px;
  border-bottom: 1px solid #e0e0e0;
  &:hover {
    background-color: #f5f5f5;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const ScheduleLabel = styled.span`
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid #e0e0e0;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Flex = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;


const availableDays: DayAttributes[] = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const DayLabels: { [key in DayAttributes]: string } = {
  monday: "Lun",
  tuesday: "Mar",
  wednesday: "Miér",
  thursday: "Jue",
  friday: "Vier",
  saturday: "Sáb",
  sunday: "Dom",
};

export const SelectScheduleRow = ({
  courtSchedule,
  onSelected,
  onUnSelected,
  isChecked,
}: {
  courtSchedule: StrapiCollection<CourtSchedule>;
  onSelected: (id: StrapiId) => void;
  onUnSelected: (id: StrapiId) => void;
  isChecked: boolean;
}) => {
  const SelectedDays = availableDays
    .map((day: DayAttributes) => {
      return (
        <CheckboxWithLabel
          viewMode
          key={day}
          checked={courtSchedule.attributes[day]}
          label={DayLabels[day]}
        ></CheckboxWithLabel>
      );
    })
    .filter((day) => day !== null);

  const startTime = moment(courtSchedule.attributes.startTime, "HH:mm").format(
    "hh:mm A"
  );
  const endTime = moment(courtSchedule.attributes.endTime, "HH:mm").format(
    "hh:mm A"
  );
  return (
    <ScheduleRow>
      <Flex style={{
        gap: "12px",
      
      }}>
        <CheckBox $big onChange={(e) => {
          if (e.target.checked){
            onSelected(courtSchedule.id)
            return;
          }
          onUnSelected(courtSchedule.id)
        }} checked={isChecked} />
        <Column>
          <Row
            style={{
              gap: "6px",
              paddingBottom: "6px",
              backgroundColor: "#333",
              padding: 6,
              marginBottom: "12px",
              color: "#fff",
            }}
          >
            <strong>Horario: </strong>
            <Row
              style={{
                gap: "6px",
              }}
            >
              <ScheduleLabel>{startTime}</ScheduleLabel>-
              <ScheduleLabel>{endTime}</ScheduleLabel>
            </Row>
          </Row>

          <Row
            style={{
              maxWidth: "300px",
              flexWrap: "wrap",
              gap: "6px",
            }}
          >
            {SelectedDays}
          </Row>
        </Column>
      </Flex>
    </ScheduleRow>
  );
};
