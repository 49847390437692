import { backendClient } from "./backendClient";
import { CourtSchedule } from "./courtScheduleApi";
import { StrapiDefaultAttributes, StrapiEntries, StrapiEntry, StrapiId, StrapiMedia, StrapiRelation, StrapiSingleRelation } from "./strapi.types";


type CreateCourtDto = {
  name: string;
}


export interface Court extends StrapiDefaultAttributes {
  name: string;
  players: number;
  disabled: boolean;
  courtSchedules?: StrapiRelation<CourtSchedule>;
  images: StrapiSingleRelation<StrapiMedia>;
}


export const courtApi = {
  create: (payload: CreateCourtDto) => {
    return backendClient.post("/api/courts", {
      data : payload
    });
  },
  delete: (id: StrapiId) => {
    return backendClient.delete(`/api/courts/${id}`);
  },
  get: () => {
    return backendClient.get<StrapiEntries<Court>>("/api/courts?sort=name:asc&populate=*");
  },
  edit: (id: StrapiId, payload: Partial<CreateCourtDto>) => {
    return backendClient.put<StrapiEntry<Court>>(`/api/courts/${id}`, {
      data: payload
    });
  },
  getOne: (id: StrapiId) => {
    return backendClient.get<StrapiEntry<Court>>(`/api/courts/${id}?populate=*`);
  },
  uploadImage: (courtId: StrapiId, data: string) => {
    console.log("uploadImage", courtId, data);
  },
  updateCourtSchedule: (courtId: StrapiId, courtSchedules: StrapiId[]) => {
    return backendClient.put(`/api/courts/${courtId}`, {
      data: {
        courtSchedules,
      }
    });
  }
}