import moment from "moment";
import { backendClient } from "./backendClient";
import { Client } from "./clientApi";
import { Court } from "./courtApi";
import { StrapiDefaultAttributes, StrapiEntries, StrapiEntry, StrapiId, StrapiSingleRelation } from "./strapi.types";


export type CreateReservationDto = {
  name: string;
  court: StrapiId;
  client: StrapiId;
  startTime: Date;
  endTime: Date;
  status: CourtReservationStatus;
}

export enum CourtReservationStatus {
  PENDING = "pending",
  CONFIRMED = "confirmed",
  STARTED = "started",
  COMPLETED = "completed",
  CANCELED = "canceled"

}


export interface CourtReservation extends StrapiDefaultAttributes {
  name: string;
  court: StrapiSingleRelation<Court>;
  client: StrapiSingleRelation<Client>;
  startTime: Date;
  endTime: Date;
  status: CourtReservationStatus;
}


export const courtReservationApi = {
  create: (payload: CreateReservationDto) => {
    return backendClient.post("/api/court-reservations", {
      data : payload
    });
  },
  delete: (id: StrapiId) => {
    return backendClient.delete(`/api/court-reservations/${id}`);
  },
  get: () => {
    return backendClient.get<StrapiEntries<CourtReservation>>("/api/court-reservations?populate=*");
  },
  getByDate: (date: Date) => {
    const startOfDay = moment(date).startOf('day').toISOString();
    const endOfDay = moment(date).endOf('day').toISOString();
    return backendClient.get<StrapiEntries<CourtReservation>>(`/api/court-reservations?filters[startTime][$gte]=${startOfDay}&filters[startTime][$lte]=${endOfDay}&sort=startTime:asc&populate=*`);
  },
  getByDateRange: (startDate: Date, endDate: Date) => {
    const startOfDate = moment(startDate).startOf('day').toISOString();
    const endOfDate = moment(endDate).endOf('day').toISOString();
    return backendClient.get<StrapiEntries<CourtReservation>>(`/api/court-reservations?filters[startTime][$gte]=${startOfDate}&filters[startTime][$lte]=${endOfDate}&pagination[limit]=10000&pagination[start]=0&sort=startTime:asc&populate[0]=court`);
  },
  getByCourtAndDate: (courtId: StrapiId, startTime: Date, endTime: Date) => {
    const startTimeISO = moment(startTime).toISOString();
    const endTimeISO = moment(endTime).toISOString();
    // This is a complex query that checks if the reservation overlaps with the given time range
    // This covers the following cases:
    // 1. Time Range Filtering:
    //    - Retrieves reservations where either:
    //      a) The start time falls within the specified range (startTimeISO to endTimeISO).
    //      b) The end time falls within the specified range (startTimeISO to endTimeISO).
    // 
    // 2. Court Filtering:
    //    - Only includes reservations for the specified court (courtId).
    // 
    // 3. Sorting:
    //    - Orders the results by start time in ascending order.
    // 
    // 4. Populate:
    //    - Includes all related fields in the response.
    
    return backendClient.get<StrapiEntries<CourtReservation>>(`/api/court-reservations?filters[$or][0][startTime][$gte]=${startTimeISO}&filters[$or][0][startTime][$lt]=${endTimeISO}&filters[$or][1][endTime][$gt]=${startTimeISO}&filters[$or][1][endTime][$lt]=${endTimeISO}&filters[court][$eq]=${courtId}&sort=startTime:asc&populate=*`);
  },
  getOne: (id: StrapiId) => {
    return backendClient.get<StrapiEntry<CourtReservation>>(`/api/court-reservations/${id}?populate=*`);
  },
  getByCourt: (courtId: StrapiId, date: Date) => {
    const startOfDay = moment(date).startOf('day').subtract(2, 'hours').toISOString();
    return backendClient.get<StrapiEntries<CourtReservation>>(`/api/court-reservations/?filters[court][$eq]=${courtId}&filters[startTime][$gte]=${startOfDay}&populate=*`);
  },
  update: (id: StrapiId, payload: Partial<CreateReservationDto>) => {
    return backendClient.put(`/api/court-reservations/${id}`, {
      data: payload
    });
  }
}