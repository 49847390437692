import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useEffect, useState } from "react";
import { CourtScheduleRow } from "./CourtScheduleRow";
import styled from "styled-components";
import { fetchCourtSchedulesAsync } from "store/courtSchedule/courtScheduleSlice";
import { CourtSchedule, courtScheduleApi } from "api/courtScheduleApi";
import { AddOrEditScheduleModal } from "./AddOrEditSchedule";
import { StrapiCollection, StrapiId } from "api/strapi.types";

const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const ScheduleList = ({
  isOpen,
  closeModal,
  openModal,
}: {
  isOpen: boolean;
  closeModal: () => void;
  openModal: () => void;
}) => {
  const { courtSchedules, loading } = useAppSelector(
    (state) => state.courtSchedule
  );
  const dispatch = useAppDispatch();
  const [editingCourtSchedule, setEditingCourtSchedule] = useState<StrapiCollection<CourtSchedule>>();

  useEffect(() => {
    console.log("fetching court schedules");
    dispatch(fetchCourtSchedulesAsync());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteCourtSchedule = async (id: StrapiId) => {
    await courtScheduleApi.delete(id);
    dispatch(fetchCourtSchedulesAsync());
  };

  const onEditCourtSchedule = async (id: StrapiId) => {
    const courtSchedule = courtSchedules.find((courtSchedule) => (courtSchedule.id === id));
    setEditingCourtSchedule(courtSchedule);
    openModal();
  };

  const onCloseModal = () => {
    setEditingCourtSchedule(undefined);
    closeModal();
  }

  return (
    <MainContainer>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          {courtSchedules.map((courtSchedule) => (
            <CourtScheduleRow
              key={courtSchedule.id}
              courtSchedule={courtSchedule}
              onDelete={deleteCourtSchedule}
              onEdit={onEditCourtSchedule}
            />
          ))}
        </div>
      )}
      <AddOrEditScheduleModal isOpen={isOpen} onClose={onCloseModal} editingCourtSchedule={editingCourtSchedule} />
    </MainContainer>
  );
};
