import { Court, courtApi } from "api/courtApi";
import { StrapiCollection } from "api/strapi.types";
import { Formik } from "formik";
import { useAppDispatch } from "hooks/reduxHooks";
import { fetchCourtsAsync } from "store/court/courtSlice";
import styled from "styled-components";
import { Button } from "ui/Button";
import { InputWithLabel } from "ui/InputWithLabel";
import Modal from "ui/Modal";
import * as Yup from "yup";

const editUserValidationSchema = Yup.object().shape({
  name: Yup.string().required("El nombre es requerido"),
});

const ModalFooter = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 20px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ModalTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 6px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
`;

const ErrorLabel = styled.span`
  color: red;
  font-size: 12px;
  margin-top: 6px;
  margin-bottom: 6px;
`;

export const EditCourtModal = ({
  isOpen,
  onClose,
  editingCourt
}: {
  isOpen: boolean;
  onClose: () => void;
  editingCourt?: StrapiCollection<Court>;
}) => {
  const dispatch = useAppDispatch();
  const editCourt = async ({
    name,
  }: {
    name: string;
  }) => {
    await courtApi.edit(editingCourt!.id, {
      name,
    });

    dispatch(fetchCourtsAsync());
    onClose();
  };

  return (
    <Modal isOpen={isOpen}>
      <Formik
        initialValues={{
          name: editingCourt?.attributes.name,
        }}
        validationSchema={editUserValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          console.log("onSubmit", values);
          editCourt({
            name: values.name!,
          }).then(() => {
            setSubmitting(false);
          }).catch((error) => {
            alert("Error al crear la cancha");
            console.error("Error creating court", error);
            setSubmitting(false)
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <>
            <ModalBody>
              <ModalTitle>Editar Cancha</ModalTitle>
              <Row>
                <InputWithLabel
                  label="Nombre"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
                {errors.name && touched.name && (
                  <ErrorLabel>errors.name</ErrorLabel>
                )}
               
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button $type="flat" onClick={onClose}>
                Cancelar
              </Button>
              <Button
                type="submit"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Guardar
              </Button>
            </ModalFooter>
          </>
        )}
      </Formik>
    </Modal>
  );
};
