import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetAction } from '../resetAction';
import { StrapiId } from 'api/strapi.types';

interface AuthState {
  isAuthenticated: boolean;
  user: User | null;
  // Add any other authentication-related state properties here
}

interface User {
  id: StrapiId;
  name: string;
  // Add any other user-related properties here
}

const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<User>) {
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    
  },
  extraReducers: builder => {
    builder.addCase(resetAction, () => initialState);
  },
});


export default authSlice;