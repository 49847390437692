import styled from "styled-components";
import { ClientList } from "./components/UserList/ClientList";
import { CreateClientModal } from "./CreateClientModal";
import { PageTopSection } from "ui/PageTopSection";

const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Clients = () => {
  return <MainContainer>
    <PageTopSection>
      <PageTopSection>Clientes</PageTopSection>
      <CreateClientModal />
    </PageTopSection>
    <ClientList />
  </MainContainer>
}