import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { StrapiCollection } from 'api/strapi.types';
import { RacketLease, racketLeaseApi } from 'api/racketLeaseApi';


type InitialState = {
  racketSale: StrapiCollection<RacketLease>[];
  openTabRacketSales: StrapiCollection<RacketLease>[];
  loading: boolean;
  error: string | undefined;
};

// Define the initial state
const initialState: InitialState = {
  racketSale: [],
  openTabRacketSales: [],
  loading: false,
  error: undefined,
};

export const fetchRacketSalesAsync = createAsyncThunk(
  'racketSale/fetchRacketSales',
  async () => {
    try {
      const response = await racketLeaseApi.get();
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch courts');
    }
  }
);

export const fetchOpenTabRacketSalesAsync = createAsyncThunk(
  'racketSale/fetchOpenTabRacketSales',
  async () => {
    try {
      const response = await racketLeaseApi.getByOpenTab();
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch open tab racket sales');
    }
  }
);

// Create the racketSlice
const racketLeaseSlice = createSlice({
  name: 'racketSale',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRacketSalesAsync.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(fetchRacketSalesAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.racketSale = action.payload.data;
      })
      .addCase(fetchRacketSalesAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // fetchOpenTabRacketSalesAsync
      .addCase(fetchOpenTabRacketSalesAsync.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(fetchOpenTabRacketSalesAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.openTabRacketSales = action.payload.data;
      })
      .addCase(fetchOpenTabRacketSalesAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default racketLeaseSlice;