import { Admin } from "./adminApi";
import { backendClient } from "./backendClient";
import { Client } from "./clientApi";
import { CourtTab } from "./courtTabApi";
import { Racket } from "./racketApi";
import { StrapiDefaultAttributes, StrapiEntries, StrapiEntry, StrapiId, StrapiSingleRelation } from "./strapi.types";


type CreateRacketLeaseDto = {
  client: StrapiId;
  courtTab: StrapiId;
  racket: StrapiId;
  user: StrapiId;
  price: number;
}


export interface RacketLease extends StrapiDefaultAttributes {
  client: StrapiSingleRelation<Client>;
  courtTab: StrapiSingleRelation<CourtTab>;
  racket: StrapiSingleRelation<Racket>;
  user: StrapiSingleRelation<Admin>;
  price: number;
}


export const racketLeaseApi = {
  create: (payload: CreateRacketLeaseDto) => {
    return backendClient.post("/api/racket-leases", {
      data: payload
    });
  },
  delete: (id: StrapiId) => {
    return backendClient.delete(`/api/racket-leases/${id}`);
  },
  get: () => {
    return backendClient.get<StrapiEntries<RacketLease>>("/api/racket-leases");
  },
  getByOpenTab: () => {
    return backendClient.get<StrapiEntries<RacketLease>>(`/api/racket-leases?filters[courtTab][status][$eq]=started&sort=createdAt:asc&&populate[0]=racket&populate[1]=racket.image&populate[2]=courtTab`);
  },
  getOne: (id: StrapiId) => {
    return backendClient.get<StrapiEntry<RacketLease>>(`/api/racket-leases/${id}?populate=*`);
  },
  update: (id: StrapiId, payload: CreateRacketLeaseDto) => {
    return backendClient.put(`/api/racket-leases/${id}`, {
      data: payload
    });
  }
}