import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Client, clientApi } from 'api/clientApi';

import { StrapiCollection } from 'api/strapi.types';


type InitialState = {
  clients: StrapiCollection<Client>[];
  loading: boolean;
  error: string | undefined;
};

// Define the initial state
const initialState: InitialState = {
  clients: [],
  loading: false,
  error: undefined,
};

// Define the async thunk to fetch admins
export const fetchClientsAsync = createAsyncThunk(
  'client/fetchClients',
  async () => {
    try {
      const response = await clientApi.get();
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch clients');
    }
  }
);

// Create the clientSlice
const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientsAsync.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(fetchClientsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.clients = action.payload.data;
      })
      .addCase(fetchClientsAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default clientSlice;