import { CourtSchedule, CourtScheduleType } from "api/courtScheduleApi";
import { CourtTab } from "api/courtTabApi";
import { InventorySale } from "api/inventorySaleApi";
import { PriceConfig } from "api/priceConfigApi";
import { RacketLease } from "api/racketLeaseApi";
import { StrapiCollection } from "api/strapi.types";
import moment from "moment";

export const courtUtils = {
  findHourlyCost: ({
    courtTab,
    courtSchedules,
    priceConfig,
  }: {
    courtTab: StrapiCollection<CourtTab> | undefined;
    courtSchedules: StrapiCollection<CourtSchedule>[];
    priceConfig: StrapiCollection<PriceConfig> | undefined;
  }) => {
    const startTime = courtTab?.attributes.startTime;
    const totalHours = Math.ceil(moment().diff(
      startTime,
      "minutes"
    ) / 60);

    let totalCost = 0;
    for (let i = 0; i < totalHours; i++) {
      let hourlyCost = 0;
      courtSchedules.forEach((schedule) => {
        const checkingHour = moment(courtTab?.attributes.startTime, "HH:mm").add(i, "hours").format('HH');
        const scheduleStartTime = moment(schedule.attributes.startTime, 'HH:mm').format('HH');
        const scheduleEndTime = moment(schedule.attributes.endTime, 'HH:mm').format('HH');
        const isOnSchedule = checkingHour >= scheduleStartTime && checkingHour < scheduleEndTime;

        // If the hour is not on schedule, use morning price
        if (!isOnSchedule) {
          hourlyCost = priceConfig?.attributes.morningPrice ?? 0;
          return;
        }
        // Check if the schedule is prime or not
        if (schedule.attributes.type === CourtScheduleType.Prime) {
          hourlyCost = priceConfig?.attributes.primePrice ?? 0;
        } else {
          hourlyCost = priceConfig?.attributes.morningPrice ?? 0;
        }
      });
      totalCost += hourlyCost;
    }

    return totalCost;
  },
  findReservationCost: ({
    startTime,
    endTime,
    courtSchedules,
    priceConfig,
  }: {
    startTime: Date;
    endTime: Date;
    courtSchedules: StrapiCollection<CourtSchedule>[];
    priceConfig: StrapiCollection<PriceConfig> | undefined;
  }) => {

    const totalHours = Math.ceil(moment(endTime).diff(
      startTime,
      "minutes"
    ) / 60);

    let totalCost = 0;
    for (let i = 0; i < totalHours; i++) {
      let hourlyCost = 0;
      courtSchedules.forEach((schedule) => {
        //We need to ensure that the time includes the web client timezone
        const startTimeWithTZ = moment(startTime)
        const intialDate = moment(startTimeWithTZ, "HH:mm");
        const checkingHour = intialDate.add(i, "hours").format('HH');
        const scheduleStartTime = moment(schedule.attributes.startTime, 'HH:mm').format('HH');
        const scheduleEndTime = moment(schedule.attributes.endTime, 'HH:mm').format('HH');
        const isOnSchedule = checkingHour >= scheduleStartTime && checkingHour < scheduleEndTime;

        // If the hour is not on schedule, use morning price
        if (!isOnSchedule) {
          hourlyCost = priceConfig?.attributes.morningPrice ?? 0;
          return;
        }
        // Check if the schedule is prime or not
        if (schedule.attributes.type === CourtScheduleType.Prime) {
          hourlyCost = priceConfig?.attributes.primePrice ?? 0;
        } else {
          hourlyCost = priceConfig?.attributes.morningPrice ?? 0;
        }
      });
      totalCost += hourlyCost;
    }

    return totalCost;
  },
  getTotalPriceItems: ({
    inventorySales,
    racketLeases
  }: {
    inventorySales: StrapiCollection<InventorySale>[];
    racketLeases: StrapiCollection<RacketLease>[];
  }) => {
    const totalInventory = inventorySales.reduce((acc, sale) => acc + (sale.attributes.qty * sale.attributes.price), 0);
    const totalRacketLease = racketLeases.reduce((acc, lease) => acc + lease.attributes.price, 0);
    return totalInventory + totalRacketLease;
  }
}