import {
  CourtReservation,
  CreateReservationDto,
  CourtReservationStatus,
  courtReservationApi,
} from "api/courtReservationApi";
import { StrapiCollection, StrapiId } from "api/strapi.types";
import { Formik } from "formik";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import moment from "moment";
import { useEffect } from "react";
import DateTimePicker from "react-datetime-picker";
import { fetchClientsAsync } from "store/client/clientSlice";
import { fetchCourtsAsync } from "store/court/courtSlice";
import styled from "styled-components";
import { Button } from "ui/Button";
import { InputWithLabel } from "ui/InputWithLabel";
import Modal from "ui/Modal";
import Select from "ui/Select";
import * as Yup from "yup";
import "react-datetime-picker/dist/DateTimePicker.css";
import { Value } from "react-datetime-picker/dist/cjs/shared/types";
import { ConfirmActionButton } from "ui/ConfirmActionButton";
import { toast } from "react-toastify";

const createReservationValidationSchema = Yup.object().shape({
  name: Yup.string().required("El nombre es requerido"),
  court: Yup.number()
    .required("La cancha es requerida")
    .test(
      "is-positive",
      "No has seleccionado la cancha",
      (value) => value >= 0
    ),
  client: Yup.number()
    .required("El cliente es requerido")
    .test(
      "is-positive",
      "No has seleccionado el cliente",
      (value) => value >= 0
    ),
  startTime: Yup.date().required("La fecha de inicio es requerida"),
  totalHours: Yup.number().required("La cantidad minima de horas es 1"),
  status: Yup.string().required("El status es requerido"),
});

const ModalFooter = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 20px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ModalTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 6px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const ErrorLabel = styled.span`
  color: red;
  font-size: 12px;
  margin-top: 6px;
  margin-bottom: 6px;
`;

const InputLabel = styled.span`
  font-size: 14px;
  font-weight: 600;
`;

export const AddReservationModal = ({
  isOpen,
  onClose,
  editingReservation,
  viewingDate,
  courtId,
  onRefresh,
}: {
  isOpen: boolean;
  onClose: () => void;
  onRefresh: () => void;
  editingReservation?: StrapiCollection<CourtReservation>;
  courtId: StrapiId;
  viewingDate: Date;
}) => {
  const dispatch = useAppDispatch();
  const courts = useAppSelector((state) => state.court.courts);
  const clients = useAppSelector((state) => state.client.clients);

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchCourtsAsync());
      dispatch(fetchClientsAsync());
    }
  }, [isOpen, dispatch]);

  const saveInventory = async ({
    name,
    client,
    court,
    startTime,
    totalHours,
    status,
  }: {
    name: string;
    client: StrapiId;
    court: StrapiId;
    startTime: Date;
    totalHours: number;
    status: CourtReservationStatus;
  }) => {
    const payload: CreateReservationDto = {
      name,
      client,
      court,
      startTime,
      endTime: moment(startTime).add(totalHours, "hour").toDate(),
      status,
    };
    const startHourChanged = !moment(payload.startTime).isSame(editingReservation?.attributes.startTime, 'hour');
    const endHourChanged = !moment(payload.endTime).isSame(editingReservation?.attributes.endTime, 'hour');
    const courtChanged = payload.court !== editingReservation?.attributes.court.data?.id;

    if(!editingReservation || startHourChanged || endHourChanged || courtChanged) {
      const { data: existingReservation } =
      await courtReservationApi.getByCourtAndDate(
        court,
        startTime,
        payload.endTime
      );
    console.log("existingReservation", existingReservation);
    const filteredClientReservations = existingReservation.data.filter((reservation) =>  reservation.attributes.client.data?.id !== client)
    if (filteredClientReservations.length > 0) {
      alert(
        "Ya existe una reservacion en ese horario, selecciona otro horario o cancha"
      );
      throw new Error("Ya existe una reservacion en ese horario");
    }
    }
    
    if (editingReservation) {
      await courtReservationApi.update(editingReservation.id, payload);
    } else {
      await courtReservationApi.create(payload);
    }

    onRefresh();
    onClose();
  };

  const courtOptions: { value: StrapiId; label: string }[] = [
    {
      value: -1,
      label: "Selecciona una cancha",
    },
  ];
  courts.forEach((court) =>
    courtOptions.push({
      value: court.id,
      label: court.attributes.name,
    })
  );

  const clientOptions: { value: StrapiId; label: string }[] = [
    {
      value: -1,
      label: "Selecciona un cliente",
    },
  ];
  clients.forEach((client) =>
    clientOptions.push({
      value: client.id,
      label: client.attributes.firstName + " " + client.attributes.lastName,
    })
  );

  const reservationStatusOptions = [
    { value: CourtReservationStatus.PENDING, label: "Pendiente" },
    { value: CourtReservationStatus.CONFIRMED, label: "Confirmado" },
  ];

  const deleteReservation = async () => {
    toast.promise(
      async () => {
        await courtReservationApi.delete(editingReservation!.id);
        onRefresh();
        onClose();
      },
      {
        error: "No se pudo borrar la reservacion",
        success: "Reservacion borrada exitosamente",
      }
    );
  };

  return (
    <Modal isOpen={isOpen}>
      <Formik
        initialValues={{
          name: editingReservation?.attributes.name ?? "",
          court: courtId,
          client: editingReservation?.attributes.client.data?.id ?? -1,
          startTime:
            editingReservation?.attributes.startTime ??
            moment(viewingDate).toDate(),
          totalHours: editingReservation
            ? moment(editingReservation?.attributes.endTime).diff(
                editingReservation?.attributes.startTime,
                "hour"
              )
            : 1,
          status:
            editingReservation?.attributes.status ??
            CourtReservationStatus.PENDING,
        }}
        validationSchema={createReservationValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          toast.promise(
            async () => {
              setSubmitting(true);
              await saveInventory({
                name: values.name,
                client: values.client,
                court: values.court,
                startTime: values.startTime,
                totalHours: values.totalHours,
                status: values.status,
              });
              setSubmitting(false);
            },
            {
              error: "Error al guardar reservacion",
              success: editingReservation ? 'Reservacion guardada con exito' : "Reservacion creada con exito",
            }
          );
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
          <>
            <ModalBody>
              <ModalTitle>
                {editingReservation ? "Editar" : "Crear"} Reservacion
              </ModalTitle>
              <Column>
                <InputWithLabel
                  label="Nombre"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
                {errors.name && touched.name && (
                  <ErrorLabel>{errors.name}</ErrorLabel>
                )}
                <InputLabel>Cancha</InputLabel>
                <Select
                  name="court"
                  options={courtOptions}
                  onChange={(e) => {
                    console.log("e", e.target);
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  value={values.court}
                />
                {errors.court && touched.court && (
                  <ErrorLabel>{errors.court}</ErrorLabel>
                )}
                <InputLabel
                  style={{
                    marginTop: "6px",
                  }}
                >
                  Cliente
                </InputLabel>
                <Select
                  name="client"
                  options={clientOptions}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.client}
                />
                {errors.client && touched.client && (
                  <ErrorLabel>{errors.client}</ErrorLabel>
                )}
                <InputLabel
                  style={{
                    marginTop: "6px",
                  }}
                >
                  Status
                </InputLabel>
                <Select
                  name="status"
                  options={reservationStatusOptions}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.status}
                />
                {errors.status && touched.status && (
                  <ErrorLabel>{errors.status}</ErrorLabel>
                )}
              </Column>
              <Column>
                <InputLabel
                  style={{
                    marginTop: "6px",
                  }}
                >
                  Hora de Inicio:
                </InputLabel>
                <DateTimePicker
                  name="startTime"
                  onChange={(value: Value) => {
                    setFieldValue("startTime", value);
                  }}
                  value={values.startTime}
                />
                {/* <InputWithLabel
                    label="Hora de inicio"
                    name="startTime"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.startTime}
                  /> */}
                {errors.startTime && touched.startTime && (
                  <ErrorLabel>{String(errors.startTime)}</ErrorLabel>
                )}
              </Column>

              <Column
                style={{
                  marginBottom: 12,
                }}
              >
                <InputWithLabel
                  label="Total de horas"
                  name="totalHours"
                  onChange={handleChange}
                  value={values.totalHours}
                />

                {errors.totalHours && touched.totalHours && (
                  <ErrorLabel>{String(errors.totalHours)}</ErrorLabel>
                )}
              </Column>
              {editingReservation && (
                <ConfirmActionButton
                  $type="danger"
                  $message="Estas seguro que deseas borrar la reservacion"
                  onClick={deleteReservation}
                >
                  Borrar Reservacion
                </ConfirmActionButton>
              )}
            </ModalBody>
            <ModalFooter>
              <Button $type="flat" onClick={onClose}>
                Cancelar
              </Button>
              <Button
                type="submit"
                onClick={() => {
                  console.log("onSubmit", values);
                  handleSubmit();
                }}
              >
                Guardar
              </Button>
            </ModalFooter>
          </>
        )}
      </Formik>

      <style>{`
        .react-datetime-picker__wrapper {
          border: 1px solid #e0e0e0;
          padding: 6px;
          border-radius: 8px;
          margin-top: 6px;
          margin-bottom: 6px;
        }
    `}</style>
    </Modal>
  );
};
