import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useEffect, useState } from "react";

import { InventoryRow } from "./InventoryRow";
import styled from "styled-components";
import { fetchInventoriesAsync } from "store/inventory/inventorySlice";
import { Inventory, inventoryApi } from "api/inventoryApi";
import { StrapiCollection, StrapiId } from "api/strapi.types";
import { AddInventoryModal } from "./AddInventoryModal";

const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const InventoryList = ({
  isOpen,
  openModal,
  closeModal,
}: {
  isOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
}) => {
  const { inventory, loading } = useAppSelector((state) => state.inventory);
  const dispatch = useAppDispatch();

  const [editingInventory, setEditingInventory] =
    useState<StrapiCollection<Inventory>>();

  useEffect(() => {
    dispatch(fetchInventoriesAsync());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteCourt = async (id: StrapiId) => {
    await inventoryApi.delete(id);
    dispatch(fetchInventoriesAsync());
    
  };

  const onEdit = (courtId: StrapiId) => {
    const inventoryItem = inventory.find(
      (inventoryItem) => inventoryItem.id === courtId
    );
    setEditingInventory(inventoryItem);
    openModal();
  };

  const onClose = () => {
    closeModal();
    setEditingInventory(undefined);
  }

  return (
    <MainContainer>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          {inventory.map((inventoryItem) => (
            <InventoryRow
              key={inventoryItem.id}
              inventory={inventoryItem}
              onDelete={deleteCourt}
              onEdit={onEdit}
            />
          ))}
        </div>
      )}
      <AddInventoryModal editingInventory={editingInventory} isOpen={isOpen} onClose={onClose} />
    </MainContainer>
  );
};
