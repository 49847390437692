
import styled from "styled-components";
import { CourtGrid } from "./component/CourtGrid";


const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;



export const CourtLease = () => {
  return (
    <MainContainer>
      <CourtGrid />
    </MainContainer>
  );
};
