import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ColorPalette } from './Color';

const DropdownContainer = styled.div`
  position: relative;
  width: 300px;
  margin-top: 6px;
  margin-bottom: 6px;
`;

const DropdownButton = styled.div`
  padding: 10px;
  min-height: 26px;
  border: 1px solid ${ColorPalette.lightGray};
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

const Option = styled.div`
  padding: 8px 12px;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const Pill = styled.span`
  padding: 5px 10px;
  background-color: ${ColorPalette.limeGreen};
  color: white;
  border-radius: 999px;
  font-size: 14px;
  display: flex;
  align-items: center;
`;

const PillCloseIcon = styled.span`
  margin-left: 5px;
  cursor: pointer;
  &:hover {
    color: #ccc;
  }
`;

const DropdownAccordion = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid ${ColorPalette.lightGray};
  border-radius: 5px;
  overflow: scroll-y;
  max-height: 200px;
`;

export interface DropDownOption {
  id: string | number;
  label: string;
}

interface MultiSelectDropdownProps {
  options: DropDownOption[];
  onSelectedOptionsChange: (selectedOptions: DropDownOption[]) => void;
}

export const MultiSelectDropdown = ({ options, onSelectedOptionsChange }: MultiSelectDropdownProps) => {
  const [selectedOptions, setSelectedOptions] = useState<DropDownOption[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const toggleOption = (option: DropDownOption) => {
    if (selectedOptions.find(so => so.id === option.id)) {
      setSelectedOptions(selectedOptions.filter(so => so.id !== option.id));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const removePill = (optionId: string | number) => {
    setSelectedOptions(selectedOptions.filter(so => so.id !== optionId));
  };

  useEffect(() => {
    onSelectedOptionsChange(selectedOptions);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  return (
    <DropdownContainer>
      <DropdownButton onClick={() => setIsOpen(!isOpen)}>
        {selectedOptions.length ? selectedOptions.map(option => (
          <Pill key={option.id}>
            {option.label}
            <PillCloseIcon onClick={() => removePill(option.id)}>x</PillCloseIcon>
          </Pill>
        )) : 'Selecciona una o mas canchas'}
      </DropdownButton>
      {isOpen && (
        <DropdownAccordion>
          {options.map(option => (
            <Option style={{
              backgroundColor: selectedOptions.find(so => so.id === option.id) ? '#f0f0f0' : 'white'
            
            }} key={option.id} onClick={() => toggleOption(option)}>
              {option.label}
            </Option>
          ))}
        </DropdownAccordion>
      )}
    </DropdownContainer>
  );
};
