import styled from "styled-components";

export const PageTopSection = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 1px 1px 2px 0 #0000000a;
`;