import {
  CourtSchedule,
  CourtScheduleType,
  CreateCourtScheduleDto,
  courtScheduleApi,
} from "api/courtScheduleApi";
import { StrapiCollection, StrapiId } from "api/strapi.types";
import { Formik } from "formik";
import { useAppDispatch } from "hooks/reduxHooks";
import moment from "moment";
import { fetchCourtSchedulesAsync } from "store/courtSchedule/courtScheduleSlice";
import styled from "styled-components";
import { Button } from "ui/Button";
import { CheckboxWithLabel } from "ui/CheckboxWithLabel";
import { InputWithLabel } from "ui/InputWithLabel";
import Modal from "ui/Modal";
import * as Yup from "yup";

// Define the regex pattern for the time format kk:mm
const timeFormatPattern =
  /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])(:([0-5]?[0-9])(\.(\d{1,3}))?)?$/;

const createUserValidationSchema = Yup.object().shape({
  startTime: Yup.string()
    .required("El horario de inicio es requerido")
    .matches(
      timeFormatPattern,
      "Hora de Inicio tiene que tener el formato de 24 horas HH:mm"
    ),
  endTime: Yup.string()
    .required("El horario de fin es requerido")
    .matches(
      timeFormatPattern,
      "Hora de Fin tiene que tener el formato de 24 horas HH:mm"
    ),
});

const ModalFooter = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 20px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
`;

const ModalTitle = styled.span`
  font-size: 24px;
  font-weight: 600;

  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 6px;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const ErrorLabel = styled.span`
  color: red;
  font-size: 12px;
  margin-top: 6px;
  margin-bottom: 6px;
`;

export const AddOrEditScheduleModal = ({
  isOpen,
  onClose,
  editingCourtSchedule,
}: {
  isOpen: boolean;
  onClose: () => void;
  editingCourtSchedule?: StrapiCollection<CourtSchedule>;
}) => {
  const dispatch = useAppDispatch();

  const createSchedule = async (params: CreateCourtScheduleDto) => {
    await courtScheduleApi.create({
      monday: params.monday,
      tuesday: params.tuesday,
      wednesday: params.wednesday,
      thursday: params.thursday,
      friday: params.friday,
      saturday: params.saturday,
      sunday: params.sunday,
      startTime: params.startTime,
      endTime: params.endTime,
      type: params.type,
    });
  };

  const editSchedule = async (
    courtScheduleId: StrapiId,
    params: CreateCourtScheduleDto
  ) => {
    await courtScheduleApi.update(courtScheduleId, {
      monday: params.monday,
      tuesday: params.tuesday,
      wednesday: params.wednesday,
      thursday: params.thursday,
      friday: params.friday,
      saturday: params.saturday,
      sunday: params.sunday,
      startTime: params.startTime,
      endTime: params.endTime,
      type: params.type,
    });
  };

  const saveSchedule = async (params: Omit<CreateCourtScheduleDto, | 'type'> & { type: boolean; }) => {
    if (editingCourtSchedule) {
      await editSchedule(editingCourtSchedule.id, {
        ...params,
        type: params.type ? CourtScheduleType.Prime : CourtScheduleType.Regular,
      });
    } else {
      await createSchedule({
        ...params,
        type: params.type ? CourtScheduleType.Prime : CourtScheduleType.Regular,
      });
    }

    dispatch(fetchCourtSchedulesAsync());
    onClose();
  };

  const initialValues = {
    monday: editingCourtSchedule?.attributes.monday ?? false,
    tuesday: editingCourtSchedule?.attributes.tuesday ?? false,
    wednesday: editingCourtSchedule?.attributes.wednesday ?? false,
    thursday: editingCourtSchedule?.attributes.thursday ?? false,
    friday: editingCourtSchedule?.attributes.friday ?? false,
    saturday: editingCourtSchedule?.attributes.saturday ?? false,
    sunday: editingCourtSchedule?.attributes.sunday ?? false,
    startTime: editingCourtSchedule?.attributes.startTime ?? "",
    endTime: editingCourtSchedule?.attributes.endTime ?? "",
    type: editingCourtSchedule?.attributes.type === CourtScheduleType.Prime ?? false,
  };

  return (
    <Modal isOpen={isOpen}>
      <Formik
        initialValues={initialValues}
        validationSchema={createUserValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          console.log("onSubmit", values);
          saveSchedule({
            monday: values.monday,
            tuesday: values.tuesday,
            wednesday: values.wednesday,
            thursday: values.thursday,
            friday: values.friday,
            saturday: values.saturday,
            sunday: values.sunday,
            startTime: moment(values.startTime, "kk:mm").format("HH:mm:ss.SSS"),
            endTime: moment(values.endTime, "kk:mm").format("HH:mm:ss.SSS"),
            type: values.type,
          })
            .then(() => {
              setSubmitting(false);
            })
            .catch((error) => {
              alert("Error al crear la cancha");
              console.error("Error creating court", error);
              setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <>
            <ModalTitle>Crear Horario</ModalTitle>
            <ModalBody>
              <Row
                style={{
                  justifyContent: "flex-start",
                }}
              >
                <Column
                  style={{
                    width: "60%",
                    backgroundColor: "#f0f0f0",
                    padding: 12,
                    borderRadius: 6,
                  }}
                >
                  <CheckboxWithLabel
                    label="Lunes"
                    name="monday"
                    width="100%"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.monday}
                  />

                  <CheckboxWithLabel
                    label="Martes"
                    name="tuesday"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.tuesday}
                  />

                  <CheckboxWithLabel
                    label="Miercoles"
                    name="wednesday"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.wednesday}
                  />

                  <CheckboxWithLabel
                    label="Jueves"
                    name="thursday"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.thursday}
                  />

                  <CheckboxWithLabel
                    label="Viernes"
                    name="friday"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.friday}
                  />

                  <CheckboxWithLabel
                    label="Sabado"
                    name="saturday"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.saturday}
                  />

                  <CheckboxWithLabel
                    label="Domingo"
                    name="sunday"
                    width="100%"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.sunday}
                  />
                </Column>
              </Row>
              <Row
                style={{
                  marginTop: 12,
                }}
              >
                <Column>
                  <InputWithLabel
                    label="Hora de inicio"
                    name="startTime"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.startTime}
                  />
                  {errors.startTime && touched.startTime && (
                    <ErrorLabel>{errors.startTime}</ErrorLabel>
                  )}
                </Column>

                <Column>
                  <InputWithLabel
                    label="Hora de fin"
                    name="endTime"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.endTime}
                  />
                  {errors.endTime && touched.endTime && (
                    <ErrorLabel>{errors.endTime}</ErrorLabel>
                  )}
                </Column>
              </Row>
              <CheckboxWithLabel
                label="Horario Prime"
                name="type"
                onChange={handleChange}
                onBlur={handleBlur}
                checked={values.type}
              />
            </ModalBody>
            <ModalFooter>
              <Button $type="flat" onClick={onClose}>
                Cancelar
              </Button>
              <Button
                type="submit"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Guardar
              </Button>
            </ModalFooter>
          </>
        )}
      </Formik>
    </Modal>
  );
};
