import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { InventorySale, inventorySaleApi } from 'api/inventorySaleApi';

import { StrapiCollection } from 'api/strapi.types';


type InitialState = {
  inventorySale: StrapiCollection<InventorySale>[];
  openTabinventorySale: StrapiCollection<InventorySale>[];
  loading: boolean;
  error: string | undefined;
};

// Define the initial state
const initialState: InitialState = {
  inventorySale: [],
  openTabinventorySale: [],
  loading: false,
  error: undefined,
};


export const fetchInventorySalesAsync = createAsyncThunk(
  'inventorySales/fetchInventorySales',
  async () => {
    try {
      const response = await inventorySaleApi.get();
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch inventorySales');
    }
  }
);

export const fetchOpenTabInventorySalesAsync = createAsyncThunk(
  'inventorySales/fetchOpenTabInventorySales',
  async () => {
    try {
      const response = await inventorySaleApi.getByOpenTab();
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch openInventorySales');
    }
  }
);

// Create the inventorySlice
const inventorySaleSlice = createSlice({
  name: 'inventorySlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInventorySalesAsync.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(fetchInventorySalesAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.inventorySale = action.payload.data;
      })
      .addCase(fetchInventorySalesAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // fetchOpenTabInventorySalesAsync
      .addCase(fetchOpenTabInventorySalesAsync.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(fetchOpenTabInventorySalesAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.openTabinventorySale = action.payload.data;
      })
      .addCase(fetchOpenTabInventorySalesAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  },
});

export default inventorySaleSlice;