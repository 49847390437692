import { Admin } from "./adminApi";
import { backendClient } from "./backendClient";
import { Client } from "./clientApi";
import { CourtTab } from "./courtTabApi";
import { Inventory } from "./inventoryApi";
import { StrapiDefaultAttributes, StrapiEntries, StrapiEntry, StrapiId, StrapiSingleRelation } from "./strapi.types";


type CreateInventorySaleDto = {
  inventory: StrapiId;
  client: StrapiId;
  courtTab: StrapiId;
  admin: StrapiId;
  price: number
  previousQty: number;
  qty: number;
  newQty: number;
}


export interface InventorySale extends StrapiDefaultAttributes {
  inventory: StrapiSingleRelation<Inventory>;
  client: StrapiSingleRelation<Client>;
  courtTab: StrapiSingleRelation<CourtTab>;
  admin: StrapiSingleRelation<Admin>;
  qty: number;
  previousQty: number;
  price: number;
  newQty: number;
}


export const inventorySaleApi = {
  create: (payload: CreateInventorySaleDto) => {
    return backendClient.post("/api/inventory-sales", {
      data : payload
    });
  },
  delete: (id: number) => {
    return backendClient.delete(`/api/inventory-sales/${id}`);
  },
  get: () => {
    return backendClient.get<StrapiEntries<InventorySale>>("/api/inventory-sales?populate=*");
  },
  getByOpenTab: () => {
    return backendClient.get<StrapiEntries<InventorySale>>(`/api/inventory-sales?filters[courtTab][status][$eq]=started&filters[courtTab][id][$null]=false&sort=createdAt:asc&populate[0]=inventory&populate[1]=inventory.image&populate[2]=courtTab`);
  },
  getOne: (id: number) => {
    return backendClient.get<StrapiEntry<InventorySale>>(`/api/inventory-sales/${id}?populate=*`);
  },
  update: (id: number, payload: CreateInventorySaleDto) => {
    return backendClient.put(`/api/inventory-sales/${id}`, {
      data: payload
    });
  }
}