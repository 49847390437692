import { auth } from "auth/auth";
import Axios from "axios";


export const backendClient = Axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

backendClient.interceptors.request.use((config) => {
  const token = auth.getToken();;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

