import { backendClient } from "./backendClient";
import { StrapiDefaultAttributes, StrapiEntries, StrapiEntry, StrapiId, StrapiMedia, StrapiSingleRelation } from "./strapi.types";
import { User } from "./userApi";


type CreateClientDto = {
  firstName: string;
  lastName: string;
  user: number | string;
  phone: string;
}

export interface Client extends StrapiDefaultAttributes {
  firstName: string;
  lastName: string;
  phone: string;
  avatar: StrapiSingleRelation<StrapiMedia>
  user: StrapiSingleRelation<User>;
}

export const clientApi = {
  create: (payload: CreateClientDto) => {
    return backendClient.post("/api/clients", {
      data: payload
    });
  },
  delete: (id: StrapiId) => {
    return backendClient.delete(`/api/clients/${id}`);
  },
  get: () => {
    return backendClient.get<StrapiEntries<Client>>("/api/clients?populate=*&pagination[limit]=10000&pagination[start]=0");
  },
  search: (searchText: string) => {
    return backendClient.get<StrapiEntries<Client>>(`/api/clients?filters[$or][0][firstName][$containsi]=${searchText}&filters[$or][1][lastName][$containsi]=${searchText}&filters[$or][2][user][email][$containsi]=${searchText}&populate=*`);
  },
  getById: (id: StrapiId) => {
    return backendClient.get<StrapiEntry<Client>>(`/api/clients/${id}?populate=*`);
  },
  edit: (id: StrapiId, payload: Partial<CreateClientDto>) => {
    return backendClient.put<StrapiEntry<Client>>(`/api/clients/${id}`, {
      data: payload
    });
  },
  uploadAvatar: (id: StrapiId, file: File) => {
    const formData = new FormData();
    formData.append("files", file);
    formData.append("ref", "api::client.client");
    formData.append("refId", id.toString());
    formData.append("field", "avatar");

    return backendClient.post<StrapiMedia>("/api/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

}