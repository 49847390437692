export const ColorPalette = {
  limeGreen: "#9DDA44",
  deepBlack: "#000000",
  charcoalGray: "#4A4A4A",
  softYellow: "#E1EB44",
  mintGreen: "#A4D555",
  lightGray: "#e0e0e0",
  green: "green",
  red: "#FF0000",
  white: "#FFFFFF",
};
