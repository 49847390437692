import Modal from "ui/Modal";
import styled from "styled-components";
import { StrapiCollection, StrapiEntry, StrapiId } from "api/strapi.types";
import { Button } from "ui/Button";
import { CourtReservation } from "api/courtReservationApi";
import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/es";
import { CourtTab, courtTabApi } from "api/courtTabApi";
import { Formik } from "formik";
import * as Yup from "yup";
import { InputWithLabel } from "ui/InputWithLabel";
import Select from "ui/Select";
import "react-datetime-picker/dist/DateTimePicker.css";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { fetchInventoriesAsync } from "store/inventory/inventorySlice";
import { inventorySaleApi } from "api/inventorySaleApi";
import { auth } from "auth/auth";
import { fetchOpenTabInventorySalesAsync } from "store/inventorySale/inventorySaleSlice";
moment.locale("es");

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  max-height: 400px;
  min-width: 450px;
  padding-bottom: 0px;
  flex: 1;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const ModalTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 6px;
`;

const ModalFooter = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 40px;
`;

const ErrorLabel = styled.span`
  color: red;
  font-size: 12px;
  margin-top: 6px;
  margin-bottom: 6px;
`;

const InputLabel = styled.span`
  font-size: 14px;
  font-weight: 600;
`;

const addItemToTabValidationSchema = Yup.object().shape({
  qty: Yup.number()
    .required("La cantidad es requerida")
    .test(
      "is-positive",
      "No se puede agregar una cantidad negativa",
      (value) => value >= 0
    ),
  inventory: Yup.number()
    .required("El producto es requerido")
    .test(
      "is-positive",
      "El producto es requerido",
      (value) => value >= 0
    ),
});

export const AddInventoryToTabModal = ({
  isOpen,
  closeModal,
  courtTabId,
}: {
  isOpen: boolean;
  closeModal: () => void;
  courtTabId: StrapiId | undefined;
}) => {
  const dispatch = useAppDispatch();
  const inventory = useAppSelector((state) => state.inventory.inventory);
  const user = auth.getUser();

  const [courtReservations, setCourtReservations] = useState<
    StrapiCollection<CourtReservation>[]
  >([]);
  const [isDataReady, setIsDataReady] = useState<boolean>(false);
  const [courtTab, setCourtTab] = useState<StrapiEntry<CourtTab>>();

  const getCourtTab = useCallback(async () => {
    if (!courtTabId) return;
    const { data } = await courtTabApi.getOne(courtTabId);
    setIsDataReady(true);
    setCourtTab(data);
  }, [courtTabId]);

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchInventoriesAsync());
      getCourtTab();
    } else {
      setIsDataReady(false);
    }
  }, [isOpen, dispatch, getCourtTab]);

  const inventoryOptions: { value: StrapiId, label: string }[] = [
    {
      value: -1,
      label: "Selecciona un producto",
    },
  ];
  inventory.forEach((inventoryItem) =>
    inventoryOptions.push({
      value: inventoryItem.id,
      label: inventoryItem.attributes.name,
    })
  );

  const createItem = async ({
    qty,
    inventoryId,
  }: {
    qty: number;
    inventoryId: StrapiId;
  }) => {

    const inventoryItem = inventory.find((item) => item.id === inventoryId);
    console.log('inventoryItem', inventoryItem);
    if (!inventoryItem) {
      throw new Error("Error al agregar inventario, intenta de nuevo.");
    }

    console.log('courtTab', courtTab);
    console.log('auth', auth);
    const client = courtTab?.data.attributes.client.data!;
    await inventorySaleApi.create({
      inventory: inventoryItem.id,
      client: client.id,
      courtTab: courtTab?.data.id!,
      admin: user!.id,
      newQty: inventoryItem.attributes.qty - qty,
      previousQty: inventoryItem.attributes.qty,
      price: inventoryItem.attributes.price,
      qty: qty,
    });
  };

  const closeViewModal = () => {
    closeModal();
    setCourtReservations([]);
  };

  const courtReservationOptions: { value: StrapiId, label: string }[] = [
    {
      value: -1,
      label: "Selecciona una reservacion",
    },
  ];
  courtReservations.forEach((courtReservation) => {
    const client = courtReservation.attributes.client.data?.attributes;
    const clientName = client?.firstName + " " + client?.lastName;
    courtReservationOptions.push({
      value: courtReservation.id,
      label: courtReservation.attributes.name + " - " + clientName,
    });
  });

  if (!courtTabId || !isOpen || !isDataReady) return null;

  return (
    <Modal isOpen={isOpen}>
      <Formik
        initialValues={{
          inventory: -1,
          qty: 1,
        }}
        validationSchema={addItemToTabValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          createItem({
            inventoryId: Number(values.inventory),
            qty: values.qty,
          })
            .then(() => {
              setSubmitting(false);
              // We need to improve this fetching the courts should also return the courtTabs
              // without having to manually fetch them
              dispatch(fetchOpenTabInventorySalesAsync());
              closeViewModal();
            })
            .catch((error) => {
              alert("Error al iniciar renta");
              console.error("Error creating courtTab", error);
              setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => {
          return (
            <>
              <ModalBody>
                <ModalTitle>Agregar Pelota</ModalTitle>
                <Column>
                  <InputLabel
                    style={{
                      marginTop: "6px",
                    }}
                  >
                    Inventory
                  </InputLabel>
                  <Select
                    name="inventory"
                    options={inventoryOptions}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.inventory}
                  />
                  {errors.inventory && touched.inventory && (
                    <ErrorLabel>{errors.inventory}</ErrorLabel>
                  )}

                  <InputWithLabel
                    label="Cantidad"
                    name="qty"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.qty}
                  />
                  {errors.qty && touched.qty && (
                    <ErrorLabel>{errors.qty}</ErrorLabel>
                  )}
                </Column>
              </ModalBody>
              <ModalFooter>
                <Button $type="flat" onClick={closeViewModal}>
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  disabled={values.inventory === -1 || values.qty === 0}
                  onClick={() => {
                    console.log("onSubmit", values);
                    handleSubmit();
                  }}
                >
                  Agregar
                </Button>
              </ModalFooter>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};
