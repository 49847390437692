import { CourtTab } from "api/courtTabApi";
import { StrapiCollection, StrapiId } from "api/strapi.types";
import { useTick } from "hooks/useTick";
import moment from "moment";
import { useMemo } from "react";
import styled from "styled-components";
import numeral from "numeral";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { courtUtils } from "utils/court.utils";
import { Button } from "ui/Button";
import { CourtTabItem } from "./components/CourtTabItem";
import { InventorySale, inventorySaleApi } from "api/inventorySaleApi";
import { fetchOpenTabInventorySalesAsync } from "store/inventorySale/inventorySaleSlice";
import { RacketLease, racketLeaseApi } from "api/racketLeaseApi";
import { fetchOpenTabRacketSalesAsync } from "store/racketLease/racketLeaseSlice";

type CourtTabInfoProps = {
  courtTab: StrapiCollection<CourtTab>;
  onAddInventoryToTab: (courtTabId: StrapiId) => void;
  onAddRacketToTab: (courtTabId: StrapiId) => void;
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const CourtTabInfo = (props: CourtTabInfoProps) => {
  const dispatch = useAppDispatch();
  const { courtTab, onAddInventoryToTab, onAddRacketToTab } = props;
  const priceConfig = useAppSelector((state) => state.priceConfig.config);
  const openTabRacketSales = useAppSelector(
    (state) => state.racketLease.openTabRacketSales
  );
  const openTabinventorySale = useAppSelector(
    (state) => state.inventorySale.openTabinventorySale
  );

  const tabRacketSales = openTabRacketSales.filter((racketLease) => racketLease.attributes.courtTab?.data?.id === courtTab.id);
  const tabInventorySales = openTabinventorySale.filter((inventorySale) => inventorySale.attributes.courtTab?.data?.id === courtTab.id);
  const courtSchedules = useAppSelector(
    (state) => state.courtSchedule.courtSchedules
  );

  const time = useTick();

  const courtTabTime = useMemo(() => {
    return moment(time).diff(courtTab.attributes.startTime, "minutes");
  }, [courtTab.attributes.startTime, time]);

  const totalCost = courtUtils.findHourlyCost({
    courtTab,
    courtSchedules,
    priceConfig,
  });

  const totalHours = Math.round(courtTabTime / 60);
  const totalMinutes = courtTabTime % 60;

  const totalPriceItems = courtUtils.getTotalPriceItems({
    inventorySales: tabInventorySales,
    racketLeases: tabRacketSales,
  })

  const granTotal = totalCost + totalPriceItems;

  const onRemoveInventorySale = async (inventorySale: StrapiCollection<InventorySale>) => {
    await inventorySaleApi.delete(inventorySale.id);
    dispatch(fetchOpenTabInventorySalesAsync());
  }

  const onRemoveRacketLease = async (racketLease: StrapiCollection<RacketLease>) => {
    await racketLeaseApi.delete(racketLease.id);
    dispatch(fetchOpenTabRacketSalesAsync());
  }

  return (
    <Column
      style={{
        padding: 6,
        flex: 1,
      }}
    >
      <Row
        style={{
          flex: 1,
          padding: "6px",
        }}
      >
        <Column
          style={{
            flex: 1,
          }}
        >
          <span>
            Hora de inicio:{" "}
            {moment(courtTab.attributes.startTime).format("HH:mm")}
          </span>

          <span>
            Tiempo: <span>{totalHours} horas </span>
            <span>{totalMinutes} minutos</span>
          </span>
        </Column>
        <Column
          style={{
            flex: 0,
          }}
        >
          <strong>Total</strong>

          <span>
            {numeral(granTotal).format("$0,0.00")}
          </span>
        </Column>
      </Row>
      <Row
        style={{
          // flex: 1,
          marginTop: 12,
          gap: 16,
          // width: "100%",
          overflowX: 'scroll',
          // minHeight: 250,
          paddingTop: 12,
          paddingBottom: 12,
        }}
      >
        {tabInventorySales.map((inventorySale) => {
          return (
            <CourtTabItem
              key={inventorySale.id}
              item={inventorySale}
              onRemoveInventorySale={onRemoveInventorySale}
              onRemoveRacketLease={onRemoveRacketLease}
            />
          );
        })}
        {tabRacketSales.map((racketLease) => {
          return (
            <CourtTabItem
              key={racketLease.id}
              item={racketLease}
              onRemoveInventorySale={onRemoveInventorySale}
              onRemoveRacketLease={onRemoveRacketLease}
            />
          );
        })}
      </Row>
      <Row
        style={{
          gap: 6,
          justifyContent: "flex-end",
        }}
      >
        <Button
          onClick={() => {
            onAddInventoryToTab(courtTab.id);
          }}
        >
          Agregar Pelotas
        </Button>
        <Button
          $type="secondary"
          onClick={() => {
            onAddRacketToTab(courtTab.id);
          }}
        >
          Agregar Palas
        </Button>
      </Row>
    </Column>
  );
};
