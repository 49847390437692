import { Client } from "api/clientApi";
import { StrapiCollection, StrapiId } from "api/strapi.types";
import styled from "styled-components";
import { Button } from "ui/Button";
import { Image } from "ui/Image";

type ClientRowProps = {
  client: StrapiCollection<Client>;
  onDelete?: (id: StrapiId) => void;
  onEdit?: (id: StrapiId) => void;
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 12px;
  border-bottom: 1px solid #e0e0e0;
  &:hover {
    background-color: #f5f5f5;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const UserIDLabel = styled.span`
  font-size: 14px;
  font-weight: 600;
`;

const Avatar = styled(Image)`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  background-color: #e0e0e0;
`;

export const ClientRow = ({ client, ...props }: ClientRowProps) => {
  return (
    <Row>
      <UserIDLabel>#{client.id}</UserIDLabel>
      <Avatar src={client.attributes.avatar.data?.attributes.formats.thumbnail.url} />
      <span>{client.attributes.firstName}</span>
      <span>{client.attributes.lastName}</span>
      <span>{client.attributes.user.data?.attributes.email}</span>
      <ButtonGroup>
        <Button $type="flat" onClick={() => props.onEdit?.(client.id)}>
          Editar
        </Button>
        <Button $type="danger" onClick={() => props.onDelete?.(client.id)}>
          Eliminar
        </Button>
      </ButtonGroup>
    </Row>
  );
};
