import styled from "styled-components";
import { SideBar } from "./components/SideBar";
import { Outlet, useLoaderData } from "react-router-dom";
import { User } from "api/userApi";
import { auth } from "auth/auth";
import { useAppDispatch } from "hooks/reduxHooks";
import { fetchPriceConfigAsync } from "store/priceConfig/priceConfigSlice";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Admin } from "api/adminApi";
import adminSlice from "store/admin/adminSlice";
import { StrapiCollection } from "api/strapi.types";

const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

const DynamicContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #eff2f6;
  padding: 22px;
`;

export const Root = () => {
  const { user, admin } = useLoaderData() as {
    user: User;
    admin: StrapiCollection<Admin>;
  };
  const dispatch = useAppDispatch();
  const isAuthenticated = auth.isAuthenticated();
  auth.setUser(user);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(adminSlice.actions.setAdmin(admin));
      dispatch(fetchPriceConfigAsync());
    }
  }, [isAuthenticated, dispatch, admin]);

  return (
    <MainContainer>
      <SideBar />
      <DynamicContainer>
        <Outlet />
      </DynamicContainer>
      <ToastContainer />
    </MainContainer>
  );
};
