

import styled from "styled-components";
import { Button } from "ui/Button";
import useModal from "hooks/useModal";
import { ScheduleList } from "./ScheduleList";

const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const TopBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`;


export const CourtSchedule = () => {

  const { isOpen, openModal, closeModal } = useModal();


  return (
    <MainContainer>
      <TopBar>
        <Button onClick={openModal}>Crear Horarios</Button>
      </TopBar>
      <ScheduleList isOpen={isOpen} closeModal={closeModal} openModal={openModal} />
      
    </MainContainer>
  );
}