// Modal.tsx
import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

interface ModalProps {
  isOpen: boolean;
  children: React.ReactNode;
}

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const ModalComponent = styled.div`
  position: fixed;
  background-color: #fff;
  padding: 12px;
  min-width: 300px;
  min-height: 300px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  zindex: 1000;
  display: flex;
  flex-direction: column;
`;

const Modal: React.FC<ModalProps> = ({ isOpen, children }) => {
  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <ModalOverlay>
      <ModalComponent>{children}</ModalComponent>
    </ModalOverlay>,
    document.body
  );
};

export default Modal;
