import { CourtEvent, courtEventApi } from "api/courtEventApi";
import { StrapiCollection } from "api/strapi.types";
import { useAppDispatch } from "hooks/reduxHooks";
import useModal from "hooks/useModal";
import moment from "moment";
import { useEffect } from "react";
import { fetchCourtEventsAsync } from "store/courtEvent/courtEventSlice";
import styled from "styled-components";
import { Button } from "ui/Button";
import { ConfirmActionButton } from "ui/ConfirmActionButton";
import Modal from "ui/Modal";

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
`;

const ModalTitle = styled.span`
  font-size: 24px;
  font-weight: 600;

  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 6px;
`;

const ModalFooter = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 20px;
`;

export const ViewEditCourtEventModal = ({
  courtEvent,
  onClose,
}: {
  courtEvent?: StrapiCollection<CourtEvent>;
  onClose: () => void;
}) => {
  const dispatch = useAppDispatch();
  const { isOpen, closeModal, openModal } = useModal();
  useEffect(() => {
    if (courtEvent) {
      openModal();
    } else {
      closeModal();
    }
  });

  const onDeleteEvent = async () => {
    if (courtEvent) {
      await courtEventApi.delete(courtEvent.id);
      dispatch(fetchCourtEventsAsync());
      onClose();
    }
  }
  return (
    <Modal isOpen={isOpen}>
      <ModalTitle>
        {courtEvent?.attributes.name}
        {courtEvent?.attributes.private ? " (Privado)" : ""}
      </ModalTitle>
      <ModalBody>
        <span>
          Fecha:{" "}
          {moment(courtEvent?.attributes.startTime).format(
            "DD/MM/YYYY hh:mm A"
          )}
        </span>
        <span>
          Hora:{" "}
          {moment(courtEvent?.attributes.endTime).format("DD/MM/YYYY hh:mm A")}
        </span>
        <span>{courtEvent?.attributes.private ? "Privado" : "Público"}</span>
      </ModalBody>
      <ModalFooter>
        <Button
          $type="flat"
          onClick={() => {
            onClose();
          }}
        >
          Cancelar
        </Button>
        <ConfirmActionButton
          $message="¿Estás seguro de que quieres borrar este evento? Esta acción no se puede deshacer."
          $type="danger"
          onClick={onDeleteEvent}
        >
          Borrar
        </ConfirmActionButton>
      </ModalFooter>
    </Modal>
  );
};
