import { backendClient } from "./backendClient";
import { StrapiDefaultAttributes, StrapiEntry } from "./strapi.types";


type CreateClientDto = {
  courtHour: string;
}

export interface PriceConfig extends StrapiDefaultAttributes {
  courtHour: number;
  morningPrice: number;
  primePrice: number;
}

export const priceConfigApi = {
  create: (payload: CreateClientDto) => {
    return backendClient.post("/api/price-config", {
      data: payload
    });
  },
  delete: (id: number) => {
    return backendClient.delete(`/api/price-config/${id}`);
  },
  get: () => {
    return backendClient.get<StrapiEntry<PriceConfig>>("/api/price-config");
  }

}