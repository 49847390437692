import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useEffect, useState } from "react";

import { RacketRow } from "./RacketRow";
import styled from "styled-components";
import { fetchRacketsAsync } from "store/racket/racketSlice";
import { Racket, racketApi } from "api/racketApi";
import { StrapiCollection, StrapiId } from "api/strapi.types";
import { AddRacketModal } from "./AddRacketModal";

const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const RacketList = ({ isOpen, openModal, closeModal }: { isOpen: boolean; openModal: () => void; closeModal: () => void; }) => {
  const { rackets, loading } = useAppSelector((state) => state.racket);
  const dispatch = useAppDispatch();
  const [editingRacket, setEditingRacket] = useState<StrapiCollection<Racket>>();

  useEffect(() => {
    dispatch(fetchRacketsAsync());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteCourt = async (id: StrapiId) => {
    await racketApi.delete(id);
    dispatch(fetchRacketsAsync());
  };

  const onEdit = (racketId: StrapiId) => {
    const racket = rackets.find((racket) => racket.id === racketId);
    setEditingRacket(racket);
    openModal();
  };

  const onClose = () => {
    closeModal();
    setEditingRacket(undefined);
  }

  return (
    <MainContainer>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          {rackets.map((racket) => (
            <RacketRow
              key={racket.id}
              racket={racket}
              onDelete={deleteCourt}
              onEdit={onEdit}
            />
          ))}
        </div>
      )}
      <AddRacketModal isOpen={isOpen} onClose={onClose} editingRacket={editingRacket} />
    </MainContainer>
  );
};
