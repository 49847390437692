import { Court } from "api/courtApi";
import { StrapiCollection, StrapiId } from "api/strapi.types";
import styled from "styled-components";
import { Button } from "ui/Button";
import { ConfirmActionButton } from "ui/ConfirmActionButton";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 12px;
  border-bottom: 1px solid #e0e0e0;
  &:hover {
    background-color: #f5f5f5;
  }
`;

const Label = styled.span`
  font-size: 16px;
  font-weight: 500;
  flex-drection: column;
`;
const SubLabel = styled.span`
  font-size: 12px;
  font-weight: 400;
  flex-drection: column;
`;

const VerticalDivider = styled.div`
  width: 1px;
  height: 30px;
  background-color: #e0e0e0;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Flex = styled.div`
  display: flex;
  flex: 1;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 20px;
  align-items: center;
`;
export const CourtRow = ({
  court,
  onDelete,
  openSchedule,
  openEvents,
  onEdit
}: {
  court: StrapiCollection<Court>;
  onDelete: (id: StrapiId) => void;
  openSchedule: (id: StrapiId) => void;
  openEvents: (id: StrapiId) => void;
  onEdit: (id: StrapiId) => void;
}) => {
  return (
    <Row>
      <Flex>
        <Column>
          <Label>{court.attributes.name}</Label>
          <SubLabel>{court.attributes.players} jugadores</SubLabel>
        </Column>
      </Flex>
      <ButtonContainer>
        <Button $type="flat" onClick={() => onEdit(court.id)}>Editar</Button>
        <Button onClick={() => openSchedule(court.id)}>Horarios</Button>
        <Button $type="secondary" onClick={() => openEvents(court.id)}>
          Eventos
        </Button>
        <VerticalDivider />
        <ConfirmActionButton $message="Estas seguro que deseas borrar la cancha" $type="danger" onClick={() => onDelete(court.id)}>
          Borrar
        </ConfirmActionButton>
      </ButtonContainer>
    </Row>
  );
};
