import { useQuery } from "hooks/useQuery";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { CourtManagement } from "./components/CourtManagement";
import { CourtSchedule } from "./components/CourtSchedule";
import { CourtEvents } from "./components/CourtEvents";

const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
`;

const Tab = styled.div<{ $active?: boolean }>`
  padding: 12px;
  border-bottom: 3px solid
    ${({ $active }) => ($active ? "#9DC554" : "transparent")};
  cursor: pointer;
  font-weight: ${({ $active }) => ($active ? "600" : "400")};
  flex: 1;
  transition: border-bottom 0.3s ease;
  &:hover {
    background-color: #f5f5f5;
    transition: background-color 0.3s ease;
  }
`;

const TabsComponent = {
  courts: CourtManagement,
  schedule: CourtSchedule,
  courtEvent: CourtEvents,
};

export const CourtTabs = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const activeTab = query.get("activeTab") || "courts";
  const ActiveTabComponent = useMemo(
    () => TabsComponent[activeTab as keyof typeof TabsComponent],
    [activeTab]
  );
  return (
    <>
      <TabsContainer>
        <Tab
          onClick={() => {
            navigate("/courts?activeTab=courts");
          }}
          $active={activeTab === "courts"}
        >
          Canchas
        </Tab>
        <Tab
          onClick={() => {
            navigate("/courts?activeTab=courtEvent");
          }}
          $active={activeTab === "courtEvent"}
        >
          Eventos
        </Tab>
        <Tab
          onClick={() => {
            navigate("/courts?activeTab=schedule");
          }}
          $active={activeTab === "schedule"}
        >
          Horarios
        </Tab>
      
      </TabsContainer>
      <ActiveTabComponent />
    </>
  );
};
