import styled from "styled-components";

const resolveUrl = (url: string) => {
  if (url.startsWith("http")) {
    return url;
  }
  return `${process.env.REACT_APP_BACKEND_URL}${url}`;
};

export const Image = styled.img.attrs(({ src }) => {
  return {
    src: resolveUrl(src ?? ''),
  };
})<{
  width?: string | number;
  height?: string | number;
  borderRadius?: string | number;
  boxShadow?: string;
}>`
  width: ${({ width }) => width ?? "auto"};
  height: ${({ height }) => height ?? "auto"};
  border-radius: ${({ borderRadius }) => borderRadius ?? "0"};
  box-shadow: ${({ boxShadow }) => boxShadow ?? "none"};
`;
