import { styled } from "styled-components";
import { Button } from "ui/Button";
import { RacketList } from "./components/RacketList";
import useModal from "hooks/useModal";
import { PageTopSection } from "ui/PageTopSection";
import { PageTopSectionTitle } from "ui/PageTopSectionTitle";

const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const TopBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 16px;
  padding: 16px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 1px 1px 2px 0 #0000000a;
`;

export const Rackets = () => {
  const { isOpen, openModal, closeModal } = useModal();
  return (
    <MainContainer>
      <PageTopSection>
        <PageTopSectionTitle>Administracion de Palas</PageTopSectionTitle>
      </PageTopSection>
      <BodyContainer>
        <TopBar>
          <Button onClick={openModal}>Crear Pala</Button>
        </TopBar>
        <RacketList isOpen={isOpen} openModal={openModal} closeModal={closeModal} />
        
      </BodyContainer>
    </MainContainer>
  );
};
