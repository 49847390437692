import styled from "styled-components";

export const Input = styled.input<{
  width?: string | number;
  height?: string | number;
}>`
  padding: 14px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  
  height: ${({ height = "auto" }) => height};
  &:focus {
    outline: none;
    border: 1px solid #9DC554;
  }
`;