import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { StrapiCollection } from 'api/strapi.types';
import { CourtSchedule, courtScheduleApi } from 'api/courtScheduleApi';


type InitialState = {
  courtSchedules: StrapiCollection<CourtSchedule>[];
  loading: boolean;
  error: string | undefined;
};

// Define the initial state
const initialState: InitialState = {
  courtSchedules: [],
  loading: false,
  error: undefined,
};

// Define the async thunk to fetch court schedules
export const fetchCourtSchedulesAsync = createAsyncThunk(
  'courts/fetchCourtSchedules',
  async () => {
    try {
      const response = await courtScheduleApi.get();
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch court schedules');
    }
  }
);

const courtScheduleSlice = createSlice({
  name: 'courtSchedule',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCourtSchedulesAsync.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(fetchCourtSchedulesAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.courtSchedules = action.payload.data;
      })
      .addCase(fetchCourtSchedulesAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default courtScheduleSlice;