import Modal from "ui/Modal";
import styled from "styled-components";
import { StrapiCollection, StrapiId } from "api/strapi.types";
import { Button } from "ui/Button";
import {
  CourtReservation,
  CourtReservationStatus,
  courtReservationApi,
} from "api/courtReservationApi";
import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/es";
import { courtTabApi } from "api/courtTabApi";
import { Formik } from "formik";
import * as Yup from "yup";
import { InputWithLabel } from "ui/InputWithLabel";
import Select from "ui/Select";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { fetchCourtsAsync } from "store/court/courtSlice";
import { fetchClientsAsync } from "store/client/clientSlice";
import { fetchCourtTabAsync } from "store/courtTab/courtTabSlice";
moment.locale("es");

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  max-height: 400px;
  min-width: 450px;
  padding-bottom: 0px;
  flex: 1;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const ModalTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 6px;
`;

const ModalFooter = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 40px;
`;

const ErrorLabel = styled.span`
  color: red;
  font-size: 12px;
  margin-top: 6px;
  margin-bottom: 6px;
`;

const InputLabel = styled.span`
  font-size: 14px;
  font-weight: 600;
`;

const createReservationValidationSchema = Yup.object().shape({
  name: Yup.string().required("El nombre es requerido"),
  court: Yup.number()
    .required("La cancha es requerida")
    .test(
      "is-positive",
      "No has seleccionado la cancha",
      (value) => value >= 0
    ),
  courtReservation: Yup.number().optional(),
  client: Yup.number()
    .required("El cliente es requerido")
    .test(
      "is-positive",
      "No has seleccionado el cliente",
      (value) => value >= 0
    ),
  startTime: Yup.date().required("La fecha de inicio es requerida"),
});

export const StartCourtTabModal = ({
  isOpen,
  closeModal,
  courtId,
  viewingDate,
  courtReservationId,
}: {
  isOpen: boolean;
  closeModal: () => void;
  courtId: StrapiId | undefined;
  viewingDate: Date;
  courtReservationId?: StrapiId;
}) => {
  const dispatch = useAppDispatch();
  const courts = useAppSelector((state) => state.court.courts);
  const clients = useAppSelector((state) => state.client.clients);

  const [courtReservations, setCourtReservations] = useState<
    StrapiCollection<CourtReservation>[]
  >([]);
  const [isDataReady, setIsDataReady] = useState<boolean>(false);

  const getCourtReservations = useCallback(
    async (courtId: StrapiId) => {
      const { data: collection } = await courtReservationApi.getByCourt(
        courtId,
        viewingDate
      );

      setCourtReservations(collection.data);
      setIsDataReady(true);
    },
    [viewingDate]
  );

  const selectedCourtReservation = courtReservations.find(
    (courtReservation) => courtReservation.id === courtReservationId
  );

  useEffect(() => {
    if (courtId && isOpen) {
      getCourtReservations(courtId);
    }
  }, [courtId, getCourtReservations, isOpen]);

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchCourtsAsync());
      dispatch(fetchClientsAsync());
    } else {
      setIsDataReady(false);
    }
  }, [isOpen, dispatch]);

  const courtOptions: { value: StrapiId; label: string }[] = [
    {
      value: -1,
      label: "Selecciona una cancha",
    },
  ];
  courts.forEach((court) =>
    courtOptions.push({
      value: court.id,
      label: court.attributes.name,
    })
  );

  const clientOptions: { value: StrapiId; label: string }[] = [
    {
      value: -1,
      label: "Selecciona un cliente",
    },
  ];
  clients.forEach((client) =>
    clientOptions.push({
      value: client.id,
      label: client.attributes.firstName + " " + client.attributes.lastName,
    })
  );

  const createCourtTab = async ({
    name,
    client,
    court,
    courtReservationId,
    startTime,
  }: {
    name: string;
    client: StrapiId;
    court: StrapiId;
    courtReservationId?: StrapiId;
    startTime: Date;
  }) => {
    await courtTabApi.create({
      name,
      client,
      court,
      courtReservation: courtReservationId,
      startTime,
    });
    if (courtReservationId) {
      await courtReservationApi.update(courtReservationId, {
        status: CourtReservationStatus.CONFIRMED,
      });
    }
  };

  const closeViewModal = () => {
    closeModal();
    setCourtReservations([]);
  };

  const courtReservationOptions: { value: StrapiId; label: string }[] = [
    {
      value: -1,
      label: "Selecciona una reservacion",
    },
  ];
  courtReservations.forEach((courtReservation) => {
    const client = courtReservation.attributes.client.data?.attributes;
    const clientName = client?.firstName + " " + client?.lastName;
    courtReservationOptions.push({
      value: courtReservation.id,
      label: courtReservation.attributes.name + " - " + clientName,
    });
  });

  if (!courtId || !isOpen || !isDataReady) return null;

  return (
    <Modal isOpen={isOpen}>
      <Formik
        initialValues={{
          name: selectedCourtReservation?.attributes.name ?? "",
          court: courtId ?? -1,
          client: selectedCourtReservation?.attributes.client.data?.id ?? -1,
          courtReservation: selectedCourtReservation?.id ?? -1,
          startTime: moment().toDate(),
        }}
        validationSchema={createReservationValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          createCourtTab({
            name: values.name,
            client: values.client,
            court: values.court,
            courtReservationId:
              values.courtReservation !== -1
                ? values.courtReservation
                : undefined,
            startTime: values.startTime,
          })
            .then(() => {
              setSubmitting(false);
              // We need to improve this fetching the courts should also return the courtTabs
              // without having to manually fetch them
              dispatch(fetchCourtsAsync());
              dispatch(fetchCourtTabAsync());
              closeViewModal();
            })
            .catch((error) => {
              alert("Error al iniciar renta");
              console.error("Error creating courtTab", error);
              setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => {
          return (
            <>
              <ModalBody>
                <ModalTitle>Iniciar Renta</ModalTitle>
                <Column>
                  <InputWithLabel
                    label="Nombre"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                  {errors.name && touched.name && (
                    <ErrorLabel>{errors.name}</ErrorLabel>
                  )}
                  <InputLabel>Cancha</InputLabel>
                  <Select
                    name="court"
                    options={courtOptions}
                    onChange={(e) => {
                      console.log("e", e.target);
                      handleChange(e);
                    }}
                    disabled={true}
                    onBlur={handleBlur}
                    value={values.court}
                  />
                  {errors.court && touched.court && (
                    <ErrorLabel>{errors.court}</ErrorLabel>
                  )}
                  <InputLabel
                    style={{
                      marginTop: "6px",
                    }}
                  >
                    Cliente
                  </InputLabel>
                  <Select
                    name="client"
                    options={clientOptions}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.client}
                  />
                  {errors.client && touched.client && (
                    <ErrorLabel>{errors.court}</ErrorLabel>
                  )}
                  <InputLabel>Reservacion</InputLabel>
                  <Select
                    name="courtReservation"
                    options={courtReservationOptions}
                    onChange={(e) => {
                      handleChange(e);
                      const courtReservation = courtReservations.find(
                        (courtReservation) =>
                          courtReservation.id === Number(e.target.value)
                      );
                      setFieldValue(
                        "client",
                        courtReservation?.attributes.client.data?.id
                      );

                      setFieldValue("name", courtReservation?.attributes.name);
                    }}
                    onBlur={handleBlur}
                    value={values.courtReservation}
                  />

                  <InputLabel
                    style={{
                      marginTop: "6px",
                    }}
                  >
                    Status
                  </InputLabel>
                </Column>
                <Column>
                  <InputLabel
                    style={{
                      marginTop: "6px",
                    }}
                  >
                    Hora de Inicio:
                  </InputLabel>
                  <DateTimePicker
                    name="startTime"
                    onChange={handleChange}
                    value={values.startTime}
                  />
                  {errors.startTime && touched.startTime && (
                    <ErrorLabel>{String(errors.startTime)}</ErrorLabel>
                  )}
                </Column>
              </ModalBody>
              <ModalFooter>
                <Button $type="flat" onClick={closeViewModal}>
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  onClick={() => {
                    console.log("onSubmit", values);
                    handleSubmit();
                  }}
                >
                  Guardar
                </Button>
              </ModalFooter>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};
