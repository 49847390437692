import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PriceConfig, priceConfigApi } from 'api/priceConfigApi';

import { StrapiCollection } from 'api/strapi.types';


type InitialState = {
  config: StrapiCollection<PriceConfig> | undefined;
  loading: boolean;
  error: string | undefined;
};

// Define the initial state
const initialState: InitialState = {
  config: undefined,
  loading: false,
  error: undefined,
};

// Define the async thunk to fetch admins
export const fetchPriceConfigAsync = createAsyncThunk(
  'priceConfig/fetchPriceConfig',
  async () => {
    try {
      const response = await priceConfigApi.get();
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch clients');
    }
  }
);

// Create the clientSlice
const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPriceConfigAsync.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(fetchPriceConfigAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.config = action.payload.data;
      })
      .addCase(fetchPriceConfigAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default clientSlice;