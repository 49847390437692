import { backendClient } from "./backendClient";
import { StrapiDefaultAttributes, StrapiId } from "./strapi.types";

export type CourtScheduleValidDays = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';

export type CreateCourtScheduleDto = {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  startTime: string;
  endTime: string;
  type: CourtScheduleType;
}

export enum CourtScheduleType {
  Regular = "regular",
  Prime = "prime",
  Elite = "elite",
}
export interface CourtSchedule extends StrapiDefaultAttributes {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  startTime: string;
  endTime: string;
  type: CourtScheduleType;
}


export const courtScheduleApi = {
  create: (payload: CreateCourtScheduleDto) => {
    return backendClient.post("/api/court-schedules", {
      data : payload
    });
  },
  update: (id: StrapiId, payload: CreateCourtScheduleDto) => {
    return backendClient.put(`/api/court-schedules/${id}`, {
      data: payload
    });
  },
  delete: (id: StrapiId) => {
    return backendClient.delete(`/api/court-schedules/${id}`);
  },
  get: () => {
    return backendClient.get("/api/court-schedules?sort=id:asc");
  },
  uploadImage: (courtId: StrapiId, data: string) => {
    console.log("uploadImage", courtId, data);
  }
}