import styled from "styled-components";
import { Calendar, SlotInfo, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { AddOrEditCourtEventModal } from "./AddOrEditCourtEvent";
import useModal from "hooks/useModal";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CalendarEvent, CourtEvent } from "api/courtEventApi";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { fetchCourtEventsAsync } from "store/courtEvent/courtEventSlice";
import { fetchCourtsAsync } from "store/court/courtSlice";
import { ViewEditCourtEventModal } from "./ViewEditCourtEvent";
import { StrapiCollection } from "api/strapi.types";
import 'moment/locale/es'; // Import Spanish locale

moment.locale("es");
const localizer = momentLocalizer(moment);

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 16px;
`;

export const CourtEvents = () => {
  const { isOpen, openModal, closeModal } = useModal();
  const [selectedDates, setSelectedDates] = useState<SlotInfo>();
  const [viewingCourtEvent, setViewingCourtEvent] = useState<StrapiCollection<CourtEvent>>();
  const dispatch = useAppDispatch();

  const courtEvents = useAppSelector((state) => state.courtEvent.courtEvents);

  const fetchData = useCallback(async () => {
    
    dispatch(fetchCourtEventsAsync());
    dispatch(fetchCourtsAsync());
    
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onSelectSlot = (slotInfo: SlotInfo) => {
    setSelectedDates(slotInfo);
    openModal();
  };

  const onSelectEvent = (event: CalendarEvent) => {
    console.log("calendar event", event);
    const courtEvent = courtEvents.find((courtEvent) => courtEvent.id === event.id);
    if(courtEvent){
      setViewingCourtEvent(courtEvent);
    }
  };

  const calendarEvents: CalendarEvent[] = useMemo(() => {
    return courtEvents.map((courtEvent) => {
      const { name, startTime, endTime } = courtEvent.attributes;
      return {
        id: courtEvent.id,
        title: name,
        start: moment(startTime).toDate(),
        end: moment(endTime).toDate(),
      };
    });
  }, [courtEvents]);

  return (
    <MainContainer>
      <Title>Eventos</Title>
      <Calendar
        localizer={localizer}
        startAccessor="start"
        events={calendarEvents}
        endAccessor="end"
        onSelectEvent={onSelectEvent}
        onSelectSlot={onSelectSlot}
        selectable
        style={{ height: 500 }}
      />
      <AddOrEditCourtEventModal
        selectedDates={selectedDates}
        isOpen={isOpen}
        onClose={closeModal}
      />
      <ViewEditCourtEventModal courtEvent={viewingCourtEvent} onClose={() => {
        setViewingCourtEvent(undefined);
      }} />
    </MainContainer>
  );
};
