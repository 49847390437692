import styled from "styled-components";

export const Button = styled.button<{
  $type?: "primary" | "secondary" | "flat" | "danger";
  width?: string;
}>`
  padding: 14px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 600;
  color: ${({ $type = "primary" }) => {
    switch ($type) {
      case "secondary":
      case "danger":
        return "#fff";
      default:
        return "#333";
    }
  }};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  background-color: ${({ $type = "primary" }) => {
    switch ($type) {
      case "primary":
        return "#9DC554";
      case "secondary":
        return "#333";
      case "danger":
        return "red";
      case "flat":
        return "#e0e0e0";
    }
  }};
  border: none;
  border-radius: 8px;
  min-width: 100px;
  width: ${({ width }) => width || "auto"};
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  &:hover ${({disabled}) => disabled ? "" : "{opacity: 0.8;}"}
`;

export const ButtonFlat = styled.button<{
  $type?: "primary" | "secondary" | "flat" | "danger";
  width?: string;
}>`
  padding: 14px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 600;
  color: ${({ $type = "primary" }) => {
    switch ($type) {
      case "secondary":
      case "danger":
        return "#fff";
      default:
        return "#333";
    }
  }};
  background-color: ${({ $type = "primary" }) => {
    switch ($type) {
      case "primary":
        return "#9DC554";
      case "secondary":
        return "#333";
      case "danger":
        return "red";
      case "flat":
        return "#e0e0e0";
    }
  }};
  border: none;
  min-width: 100px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  width: ${({ width }) => width || "auto"};
  &:hover {
    opacity: 0.8;
  }
`;

export const ButtonInput = styled.input<{
  $type?: "primary" | "secondary" | "flat" | "danger";
  width?: string;
}>`
  padding: 14px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 600;
  color: ${({ $type = "primary" }) => {
    switch ($type) {
      case "secondary":
      case "danger":
        return "#fff";
      default:
        return "#333";
    }
  }};
  background-color: ${({ $type = "primary" }) => {
    switch ($type) {
      case "primary":
        return "#9DC554";
      case "secondary":
        return "#333";
      case "danger":
        return "red";
      case "flat":
        return "#e0e0e0";
    }
  }};
  border: none;
  border-radius: 8px;
  min-width: 100px;
  width: ${({ width }) => width || "auto"};
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  &:hover {
    opacity: 0.8;
  }
`;

export const FloatingButton = styled(Button)<{
  $position: "bottom-right" | "bottom-left";
}>`
  position: fixed;
  bottom: 30px;
  height: 60px;
  width: 60px;
  min-width: 60px;
  border-radius: 50%;
  font-size: 24px;
  ${({ $position }) => {
    switch ($position) {
      case "bottom-right":
        return "right: 30px;";
      case "bottom-left":
        return "left: 30px;";
    }
  }}
`;