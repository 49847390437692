import { backendClient } from "./backendClient";
import { Court } from "./courtApi";
import { StrapiDefaultAttributes, StrapiId, StrapiRelation } from "./strapi.types";
import { Event } from 'react-big-calendar'


export interface CalendarEvent extends Event{
  id: StrapiId;
}


export type CreateCourtEventDto = {
  name: string;
  startTime: Date;
  endTime: Date;
  courts: (number | string)[];
  private: boolean;
}


export interface CourtEvent extends StrapiDefaultAttributes {
  name: string;
  startTime: Date;
  endTime: Date;
  private: boolean;
  courts?: StrapiRelation<Court>;
}


export const courtEventApi = {
  create: (payload: CreateCourtEventDto) => {
    return backendClient.post("/api/court-events", {
      data : payload
    });
  },
  update: (id: StrapiId, payload: CreateCourtEventDto) => {
    return backendClient.put(`/api/court-events/${id}`, {
      data: payload
    });
  },
  delete: (id: StrapiId) => {
    return backendClient.delete(`/api/court-events/${id}`);
  },
  get: () => {
    return backendClient.get("/api/court-events");
  },
  getByCourtId: (id: StrapiId) => {
    return backendClient.get(`/api/court-events?courts.id=${id}`);
  },
  uploadImage: (courtId: StrapiId, data: string) => {
    console.log("uploadImage", courtId, data);
  }
}