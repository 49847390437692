import { InventorySale } from "api/inventorySaleApi";
import { RacketLease } from "api/racketLeaseApi";
import { StrapiCollection } from "api/strapi.types";
import numeral from "numeral";
import styled from "styled-components";
import { ColorPalette } from "ui/Color";
import { ConfirmActionWrapper } from "ui/ConfirmActionButton";

type CourtTabItemProps = {
  item: StrapiCollection<RacketLease | InventorySale>;
  onRemoveRacketLease: (racketLease: StrapiCollection<RacketLease>) => void;
  onRemoveInventorySale: (
    inventorySale: StrapiCollection<InventorySale>
  ) => void;
};

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 6px;
  width: 140px;
  height: 160px;
  align-items: center;
  padding: 6px;
  border: 1px solid #e0e0e0;
  padding-top: 12px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const CloseContainer = styled.div`
  display: flex;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: red;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  position: absolute;
  right: -10px;
  top: -10px;
  cursor: pointer;
`;

const PriceLabel = styled.span`
  font-size: 14px;
  font-weight: 600;
  background-color: ${ColorPalette.mintGreen};
  padding: 6px;
  border-radius: 4px;
`;

const ItemImage = styled.img`
  width: 80px;
  height: 80px;
`;

export const CourtTabItem = (props: CourtTabItemProps) => {
  const { item, onRemoveInventorySale, onRemoveRacketLease } = props;

  const racketLease = item as StrapiCollection<RacketLease>;
  const inventorySale = item as StrapiCollection<InventorySale>;
  let imageUrl: string | undefined;

  if (racketLease?.attributes.racket) {
    imageUrl =
      racketLease.attributes.racket.data?.attributes.image.data?.attributes
        .formats.thumbnail.url;
  }

  if (inventorySale?.attributes.inventory) {
    imageUrl =
      inventorySale.attributes.inventory.data?.attributes.image.data?.attributes
        .formats.thumbnail.url;
  }
  const onRemove = () => {
    if (inventorySale.attributes.inventory) {
      onRemoveInventorySale(inventorySale);
      return;
    }

    onRemoveRacketLease(racketLease);
  };
  
  const itemPrice = item.attributes.price
  const itemName =
    racketLease?.attributes?.racket?.data?.attributes?.name ??
    inventorySale?.attributes?.inventory?.data?.attributes?.name ??
    "";

  return (
    <Container>
      <ConfirmActionWrapper
        $message={`Estas seguro que quieres remover ${itemName}?`}
        onClick={onRemove}
      >
        <CloseContainer>
          <span>X</span>
        </CloseContainer>
      </ConfirmActionWrapper>

      <ItemImage src={imageUrl} alt={itemName} />
      <Row
        style={{
          padding: 6,
          flex: 1,
        }}
      >
        <span>{itemName}</span>
      </Row>
      <PriceLabel>{numeral(itemPrice).format("$0,0.0")}</PriceLabel>
    </Container>
  );
};
