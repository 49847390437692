
import { StrapiCollection, StrapiId } from "api/strapi.types";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import moment from "moment";
import { SlotInfo } from "react-big-calendar";
import styled from "styled-components";
import { Button } from "ui/Button";
import { InputWithLabel } from "ui/InputWithLabel";
import Modal from "ui/Modal";

import DateTimePicker from "react-datetime-picker";
import { useEffect, useState } from "react";
import { Value } from "react-datetime-picker/dist/cjs/shared/types";

import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { CourtEvent, CreateCourtEventDto, courtEventApi } from "api/courtEventApi";
import { CheckboxWithLabel } from "ui/CheckboxWithLabel";
import { fetchCourtEventsAsync } from "store/courtEvent/courtEventSlice";
import { DropDownOption, MultiSelectDropdown } from "ui/MultiSelectDropdown";

const ModalFooter = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 20px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
`;

const ModalTitle = styled.span`
  font-size: 24px;
  font-weight: 600;

  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 6px;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const ErrorLabel = styled.span`
  color: red;
  font-size: 12px;
  margin-top: 6px;
  margin-bottom: 6px;
`;

const InputTimeLabel = styled.span`
  font-size: 16px;
  font-weight: 500;
`;

export const AddOrEditCourtEventModal = ({
  selectedDates,
  isOpen,
  onClose,
  editingCourtEvent,
}: {
  selectedDates?: SlotInfo;
  isOpen: boolean;
  onClose: () => void;
  editingCourtEvent?: StrapiCollection<CourtEvent>;
}) => {
  const dispatch = useAppDispatch();
  const [startDate, setStartDate] = useState<Value>();
  const [endDate, setEndDate] = useState<Value>();
  const [isPrivateEvent, setIsPrivateEvent] = useState(false);
  const [eventName, setEventName] = useState<string>();
  const [selectedCourts, setSelectedCourts] = useState<(number | string)[]>();
  const courts = useAppSelector((state) => state.court.courts);

  useEffect(() => {
    if (selectedDates) {
      setStartDate(selectedDates.start);
      setEndDate(selectedDates.end);
    }
  }, [selectedDates]);

  const timeError = moment(startDate).isSameOrAfter(endDate);

  const createSchedule = async (params: CreateCourtEventDto) => {
    await courtEventApi.create({
      startTime: params.startTime,
      endTime: params.endTime,
      name: params.name,
      courts: params.courts,
      private: params.private
    });
  };

  const editSchedule = async (
    courtScheduleId: StrapiId,
    params: CreateCourtEventDto
  ) => {
    await courtEventApi.update(courtScheduleId, {
      name: params.name,
      startTime: params.startTime,
      endTime: params.endTime,
      courts: params.courts,
      private: params.private
    });
  };

  const saveEvent = async (params: CreateCourtEventDto) => {
    if (editingCourtEvent) {
      await editSchedule(editingCourtEvent.id, params);
    } else {
      await createSchedule(params);
    }

    dispatch(fetchCourtEventsAsync());
    onClose();
  };

  const handleSubmit = async () => {
    if(!startDate || !endDate || !eventName || !selectedCourts) {
      alert("Por favor llene todos los campos");
      return;
    }

    if(timeError) {
      alert("Fechas Invalida, la fecha de fin debe de ser despues de la fecha de inicio");
      return;
    }
    saveEvent({
      name: eventName,
      startTime: moment(startDate?.toDateString()).toDate(),
      endTime: moment(endDate?.toDateString()).toDate(),
      courts: selectedCourts,
      private: isPrivateEvent
    })
      .then(() => {
        //setSubmitting(false);
      })
      .catch((error) => {
        alert("Error al crear la cancha");
        console.error("Error creating court", error);
        //setSubmitting(false);
      });
  }

  

  const options: DropDownOption[] = courts.map((court) => ({
    label: court.attributes.name,
    id: court.id,
  }));

  const onSelectedOptionsChange = (selectedOptions: DropDownOption[]) => {
    setSelectedCourts(selectedOptions.map((option) => {
      return option.id;
    }));
  }

  return (
    <Modal isOpen={isOpen}>
      <>
        <ModalTitle>Crear Evento</ModalTitle>
        <ModalBody>
          <Row>
            <Column>
              <InputTimeLabel>Canchas</InputTimeLabel>
              <MultiSelectDropdown options={options} onSelectedOptionsChange={onSelectedOptionsChange}/>
              <InputWithLabel label="Nombre del evento" name="eventName" onChange={(e) => setEventName(e.target.value)} />
              <CheckboxWithLabel label="Evento privado" checked={isPrivateEvent} onChange={(e) => {
                setIsPrivateEvent(e.target.checked);
              }} />
            </Column>
          </Row>
          <Row
            style={{
              marginTop: 12,
              borderTop: '1px solid #e0e0e0',
              paddingTop: 12,
            }}
          >
            <Column>
              <InputTimeLabel>Hora de inicio</InputTimeLabel>
              <DateTimePicker onChange={setStartDate} value={startDate} />


              <InputTimeLabel>Hora de fin</InputTimeLabel>
              <DateTimePicker onChange={setEndDate} value={endDate} />
            </Column>

          </Row>
          {timeError && (
                <ErrorLabel>Fechas Invalida, la fecha de fin debe de ser despues de la fecha de inicio</ErrorLabel>
              )}
        </ModalBody>
        <ModalFooter>
          <Button $type="flat" onClick={onClose}>
            Cancelar
          </Button>
          <Button
            type="submit"
            onClick={() => {
              handleSubmit();
            }}
          >
            Guardar
          </Button>
        </ModalFooter>
      </>
      <style>{`
        .react-datetime-picker__wrapper {
          border: 1px solid #e0e0e0;
          padding: 6px;
          border-radius: 8px;
          margin-top: 6px;
          margin-bottom: 6px;
        }
    `}</style>
    </Modal>
  );
};
