import styled from "styled-components";
import { Input } from "./Input";
import { InputHTMLAttributes, useState } from "react";

const InputContainer = styled.div<{
  width?: string | number;
}>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 12px;
  width: ${({ width = "auto" }) => width};
`;

const InputLabel = styled.span`
  font-size: 14px;
  font-weight: 600;
`;

const ShowPasswordLabel = styled.label`
  font-size: 12px;
  color: #0000008a;
  cursor: pointer;
  text-decoration: underline;
`;

interface InputWithLabelProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  placeholder?: string;
}
export const InputWithLabel = ({
  label,
  placeholder,
  width,
  type = "text",
  ...props
}: InputWithLabelProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(type === "password" ? false : true);
  const togglePassword = () => {
    setShowPassword(!showPassword);
  }
  const finalType = showPassword ? "text": type;

  return (
    <InputContainer width={width}>
      <InputLabel>{label}</InputLabel>
      <Input type={finalType} placeholder={placeholder ?? label} width={width} {...props} />
      {type === "password" && (<ShowPasswordLabel onClick={togglePassword}>{finalType === 'text' ? 'Ocultar' : 'Mostrar'} contraseña</ShowPasswordLabel>)}
    </InputContainer>
  );
};
