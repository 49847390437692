import styled from "styled-components";

const MetricCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  flex: 1;
  margin: 6px;
`;

const MetricCardContent = styled.div`
  padding: 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #f3f3f3;
  border-radius: 8px;
  box-shadow: 0px 0px 2px 0 rgba(0, 0, 0, 0.1);
`;

const MetricCardTitle = styled.span`
  font-size: 16px;
  font-weight: 600;
  align-self: center;
`;

const MetricCardValue = styled.span`
  font-size: 26px;
  font-weight: 600;
`;

export const MetricCard = ({
  title,
  value,
}: {
  title: string;
  value: string | number;
}) => {
  return (
    <MetricCardContainer>
      <MetricCardTitle>{title}</MetricCardTitle>
      <MetricCardContent>
        <MetricCardValue>{value}</MetricCardValue>
      </MetricCardContent>
    </MetricCardContainer>
  );
};
