
import styled from "styled-components";
import { Button } from "ui/Button";
import { AddCourtModal } from "./AddCourtModal";
import useModal from "hooks/useModal";
import { CourtList } from "./components/CourtList";

const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const TopBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`;


export const CourtManagement = () => {
  const { isOpen, openModal, closeModal } = useModal();
  return (
    <MainContainer id="court-management">
      <TopBar>
        <Button onClick={openModal}>Crear Cancha</Button>
      </TopBar>
      <CourtList />
      <AddCourtModal isOpen={isOpen} onClose={closeModal} />
    </MainContainer>
  );
};
