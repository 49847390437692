import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const NavContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const NavItem = styled.span`
  font-size: 14px;
  color: #666666;
  cursor: pointer;
  &:hover {
    color: #000000;
  }
  margin-right: 3px;
  margin-left: 3px;
`;

type BreadcrumbPath = {
  label: string;
  selected: boolean;
  path?: string;
};

export const Breadcrumb = ({ path }: { path: BreadcrumbPath[] }) => {
  const navigate = useNavigate();

  return (
    <NavContainer>
      <NavItem>Inicio</NavItem>
      {path.map((item, index) => {
        return (
          <div key={index}>
            <span
              style={{
                fontWeight: "400",
                marginLeft: "3px",
                marginRight: "3px",
              }}
            >
              {">"}
            </span>
            <NavItem
              
              onClick={() => {
                if (item.path) {
                  navigate(item.path);
                }
              }}
              style={{ fontWeight: item.selected ? "600" : "400" }}
            >
              {item.label}
            </NavItem>
          </div>
        );
      })}
    </NavContainer>
  );
};
