import { backendClient } from "./backendClient";
import { StrapiDefaultAttributes, StrapiEntries, StrapiEntry, StrapiId, StrapiMedia, StrapiSingleRelation } from "./strapi.types";


type CreateRacketDto = {
  name: string;
  description: string;
  price: number;
  available: boolean;
}


export interface Racket extends StrapiDefaultAttributes {
  name: string;
  description: string;
  image: StrapiSingleRelation<StrapiMedia>;
  price: number;
  available: boolean;
}


export const racketApi = {
  create: (payload: CreateRacketDto) => {
    return backendClient.post("/api/rackets", {
      data: payload
    });
  },
  delete: (id: StrapiId) => {
    return backendClient.delete(`/api/rackets/${id}`);
  },
  get: () => {
    return backendClient.get<StrapiEntries<Racket>>("/api/rackets");
  },
  getOne: (id: StrapiId) => {
    return backendClient.get<StrapiEntry<Racket>>(`/api/rackets/${id}?populate=*`);
  },
  update: (id: StrapiId, payload: CreateRacketDto) => {
    return backendClient.put(`/api/rackets/${id}`, {
      data: payload
    });
  }
}