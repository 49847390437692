import styled from "styled-components";
import { CheckBox } from "./Checkbox";
import { InputHTMLAttributes } from "react";
import { ColorPalette } from "./Color";

const CheckboxLabel = styled.div<{width?: string }>`
  display: flex;
  align-items: center;
  width: ${({ width }) => width || 'auto'};
  gap: 8px;
  justify-content: space-between;
`;

interface InputCheckBoxWithLabelProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  width?: string;
  viewMode?: boolean;
}

const Label = styled.span`
  font-size: 16px;
  font-weight: 500;
  min-width: 50px;
`;

const CheckMark = () => (
  <svg fill={ColorPalette.green} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>
);

const CloseMark = () => {
  return <svg fill={ColorPalette.red} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"/></svg>
}

export const CheckboxWithLabel = ({ label, width, viewMode, ...props }: InputCheckBoxWithLabelProps ) => {
  const viewModeView = props.checked ? <CheckMark /> : <CloseMark />;
  return (
    <CheckboxLabel width={width}>
      <Label>{label}</Label>
      {viewMode ? viewModeView :<CheckBox $big {...props} />}
    </CheckboxLabel>
  );
}