import { Racket, racketApi } from "api/racketApi";
import { StrapiCollection } from "api/strapi.types";
import { Formik } from "formik";
import { useAppDispatch } from "hooks/reduxHooks";
import { fetchRacketsAsync } from "store/racket/racketSlice";
import styled from "styled-components";
import { Button } from "ui/Button";
import { CheckboxWithLabel } from "ui/CheckboxWithLabel";
import { InputWithLabel } from "ui/InputWithLabel";
import Modal from "ui/Modal";
import * as Yup from "yup";

const createRacketValidationSchema = Yup.object().shape({
  name: Yup.string().required("El nombre es requerido"),
  description: Yup.string().required("La descripcion es requerida"),
  price: Yup.number().required("El precio es requerido"),
  available: Yup.bool()
});

const ModalFooter = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 20px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ModalTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 6px;
`;


const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const ErrorLabel = styled.span`
  color: red;
  font-size: 12px;
  margin-top: 6px;
  margin-bottom: 6px;
`;

export const AddRacketModal = ({
  isOpen,
  onClose,
  editingRacket
}: {
  isOpen: boolean;
  onClose: () => void;
  editingRacket?: StrapiCollection<Racket>;
}) => {
  const dispatch = useAppDispatch();
  const createRacket = async ({
    name,
    description,
    price,
    available,
  }: {
    name: string;
    description: string;
    price: number;
    available: boolean;
  }) => {
    const payload = {
      name,
      description,
      price,
      available: available,
    }
    if(editingRacket){
      await racketApi.update(editingRacket.id, payload);
    } else {
      await racketApi.create(payload);
    }

    dispatch(fetchRacketsAsync());
    onClose();
  };

  return (
    <Modal isOpen={isOpen}>
      <Formik
        initialValues={{
          name: editingRacket?.attributes.name ?? "",
          description: editingRacket?.attributes.description ?? "",
          price: editingRacket?.attributes.price ?? 0,
          available: editingRacket?.attributes.available ?? false,
        }}
        validationSchema={createRacketValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          console.log("onSubmit", values);
          createRacket({
            name: values.name,
            description: values.description,
            price: values.price,
            available: values.available,
          }).then(() => {
            setSubmitting(false);
          }).catch((error) => {
            alert("Error al crear la pala");
            console.error("Error saving racket", error);
            setSubmitting(false)
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <>
            <ModalBody>
              <ModalTitle>Crear Pala</ModalTitle>
              <Column>
                <InputWithLabel
                  label="Nombre"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
                {errors.name && touched.name && (
                  <ErrorLabel>{errors.name}</ErrorLabel>
                )}
                <InputWithLabel
                  label="Descripcion"
                  name="description"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                />
                {errors.description && touched.description && (
                  <ErrorLabel>{errors.description}</ErrorLabel>
                )}
                <InputWithLabel
                  label="Precio"
                  name="price"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.price}
                />
                {errors.price && touched.price && (
                  <ErrorLabel>{errors.price}</ErrorLabel>
                )}
                <CheckboxWithLabel
                  label="Disponible"
                  name="available"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={values.available}
                />
                {errors.available && touched.available && (
                  <ErrorLabel>{errors.available}</ErrorLabel>
                )}
               
              </Column>
            </ModalBody>
            <ModalFooter>
              <Button $type="flat" onClick={onClose}>
                Cancelar
              </Button>
              <Button
                type="submit"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Guardar
              </Button>
            </ModalFooter>
          </>
        )}
      </Formik>
    </Modal>
  );
};
