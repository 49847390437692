import { CourtSchedule } from "api/courtScheduleApi";
import { StrapiCollection, StrapiId } from "api/strapi.types";
import moment from "moment";
import styled from "styled-components";
import { Button } from "ui/Button";
import { CheckboxWithLabel } from "ui/CheckboxWithLabel";
import { ConfirmActionButton } from "ui/ConfirmActionButton";

const ScheduleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  padding-left: 14px;
  padding-right: 14px;
  margin-bottom: 12px;
  border-bottom: 1px solid #e0e0e0;
  &:hover {
    background-color: #f5f5f5;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const ScheduleLabel = styled.span`
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid #e0e0e0;
`;

const VerticalDivider = styled.div`
  width: 1px;
  height: 30px;
  background-color: #e0e0e0;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Flex = styled.div`
  display: flex;
  flex: 1;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 20px;
  align-items: center;
`;
export type DayAttributes = keyof Omit<
  CourtSchedule,
  "created_at" | "updated_at" | "publishedAt" | "startTime" | "endTime" | "id" | "type"
>;

const availableDays: DayAttributes[] = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const DayLabels: { [key in DayAttributes]: string } = {
  monday: "Lun",
  tuesday: "Mar",
  wednesday: "Miér",
  thursday: "Jue",
  friday: "Vier",
  saturday: "Sáb",
  sunday: "Dom",
};

export const CourtScheduleRow = ({
  courtSchedule,
  onDelete,
  onEdit
}: {
  courtSchedule: StrapiCollection<CourtSchedule>;
  onDelete: (id: StrapiId) => void;
  onEdit: (id: StrapiId) => void;
}) => {
  const SelectedDays = availableDays
    .map((day: DayAttributes) => {
      return (
        <CheckboxWithLabel
          key={day}
          readOnly
          checked={courtSchedule.attributes[day]}
          label={DayLabels[day]}
        ></CheckboxWithLabel>
      );
    })
    .filter((day) => day !== null);

    console.log('courtSchedule.attributes.endTime', courtSchedule.attributes.endTime);
  const startTime = moment(courtSchedule.attributes.startTime, "HH:mm:ss").format(
    "hh:mm A"
  );
  const endTime = moment(courtSchedule.attributes.endTime, "HH:mm:ss").format(
    "hh:mm A"
  );
  return (
    <ScheduleRow>
      <Flex>
        <Column>
          <Row style={{
            gap: "6px",
            paddingBottom: "6px",
            backgroundColor: "#333",
            padding: 6,
            marginBottom: '12px',
            color: "#fff"
          }}>
            <strong>Horario: </strong>
            <Row style={{
              gap: "6px"
            }}>
              <ScheduleLabel>{startTime}</ScheduleLabel>
              -
              <ScheduleLabel>{endTime}</ScheduleLabel>
            </Row>
          </Row>

          <Row
            style={{
              maxWidth: "300px",
              flexWrap: "wrap",
              gap: "6px",
            }}
          >
            {SelectedDays}
          </Row>
        </Column>
      </Flex>
      <ButtonContainer>
        <Button $type="secondary" onClick={() => onEdit(courtSchedule.id)}>
          Editar
        </Button>
        <VerticalDivider />
        <ConfirmActionButton $message="Estas seguro que deseas borrar el horario?" $type="danger" onClick={() => onDelete(courtSchedule.id)}>
          Borrar
        </ConfirmActionButton>
      </ButtonContainer>
    </ScheduleRow>
  );
};
