import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { StrapiCollection } from 'api/strapi.types';
import { Inventory, inventoryApi } from 'api/inventoryApi';


type InitialState = {
  inventory: StrapiCollection<Inventory>[];
  loading: boolean;
  error: string | undefined;
};

// Define the initial state
const initialState: InitialState = {
  inventory: [],
  loading: false,
  error: undefined,
};

// Define the async thunk to fetch courts
export const fetchInventoriesAsync = createAsyncThunk(
  'inventory/fetchInventories',
  async () => {
    try {
      const response = await inventoryApi.get();
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch courts');
    }
  }
);

// Create the inventorySlice
const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInventoriesAsync.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(fetchInventoriesAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.inventory = action.payload.data;
      })
      .addCase(fetchInventoriesAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default inventorySlice;