import styled from "styled-components";
import { UserList } from "./components/UserList/UserList";
import { CreateUserModal } from "./CreateUserModal";
import { PageTopSection } from "ui/PageTopSection";

const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;






export const Users = () => {
  return <MainContainer>
    <PageTopSection>
      <PageTopSection>Usuarios</PageTopSection>
      <CreateUserModal />
    </PageTopSection>
    <UserList />
  </MainContainer>
}