import styled from "styled-components";
import useModal from "../../hooks/useModal";
import { Button } from "../../ui/Button";
import Modal from "../../ui/Modal";
import { Formik } from "formik";
import { InputWithLabel } from "../../ui/InputWithLabel";
import * as Yup from "yup";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { fetchClientsAsync } from "store/client/clientSlice";
import { Roles, userApi } from "api/userApi";
import { clientApi } from "api/clientApi";

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  flex: 1;
`;

const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
  padding-top: 12px;
  border-top: 1px solid #e0e0e0;
`;

const ModalTitle = styled.span`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 12px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 12px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
`;

const createClientValidationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email es requerido"),
  password: Yup.string()
    .required("Contraseña es requerida")
    .min(6, "La contraseña debe tener al menos 6 caracteres"),
  firstName: Yup.string().required("Nombre es requerido"),
  lastName: Yup.string().required("Apellido es requerido"),
});

const ErrorLabel = styled.span`
  color: red;
  font-size: 12px;
  margin-top: 6px;
  margin-bottom: 6px;
`;

export const CreateClientModal = () => {
  const { isOpen, openModal, closeModal } = useModal();
  const dispatch = useAppDispatch();

  const createClient = async ({
    firstName,
    lastName,
    email,
    password,
    phone
  }: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    phone: string;
  }) => {
    const { data: clientData } = await userApi.createUser({
      email,
      password,
      username: email,
      role: Roles.Client,
      confirmed: true,
    });


    await clientApi.create({
      user: clientData.id,
      firstName,
      lastName,
      phone
    });

    dispatch(fetchClientsAsync());

    closeModal();
  };

  return (
    <div>
      <Button onClick={openModal}>Crear Cliente</Button>

      <Modal isOpen={isOpen}>
        <Formik
          initialValues={{
            email: "",
            password: "",
            firstName: "",
            lastName: "",
            phone: "",
          }}
          validationSchema={createClientValidationSchema}
          onSubmit={(values, { setSubmitting }) => {
            console.log("onSubmit", values);
            createClient({
              email: values.email,
              password: values.password,
              firstName: values.firstName,
              lastName: values.lastName,
              phone: values.phone,
            }).then(() => {
              setSubmitting(false);
            });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <>
              <ModalBody>
                <ModalTitle>Agregar Cliente</ModalTitle>
                <Row>
                  <InputWithLabel
                    label="Nombre"
                    name="firstName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                  />
                  {errors.firstName && touched.firstName && (
                    <ErrorLabel>errors.firstName</ErrorLabel>
                  )}
                  <InputWithLabel
                    label="Apellido"
                    name="lastName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                  />
                  {errors.lastName && touched.lastName && (
                    <ErrorLabel>{errors.lastName}</ErrorLabel>
                  )}
                </Row>
                <InputWithLabel
                  label="Email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                {errors.email && touched.email && (
                  <ErrorLabel>{errors.email}</ErrorLabel>
                )}
                <InputWithLabel
                  label="Telefono"
                  name="phone"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                />
                {errors.phone && touched.phone && (
                  <ErrorLabel>{errors.phone}</ErrorLabel>
                )}
                <InputWithLabel
                  label="Contraseña"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="password"
                  value={values.password}
                />
                {errors.password && touched.password && (
                  <ErrorLabel>{errors.password}</ErrorLabel>
                )}
              </ModalBody>
              <ModalFooter>
                <Button $type="flat" onClick={closeModal}>
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  onClick={() => {
                    console.log("click");
                    handleSubmit();
                  }}
                >
                  Crear
                </Button>
              </ModalFooter>
            </>
          )}
        </Formik>
      </Modal>
    </div>
  );
};
