import { CourtTabStatus, courtTabApi } from "api/courtTabApi";
import { StrapiId } from "api/strapi.types";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import moment from "moment";
import numeral from "numeral";
import { useMemo } from "react";
import { fetchCourtTabAsync } from "store/courtTab/courtTabSlice";
import styled from "styled-components";
import { Button } from "ui/Button";
import Modal from "ui/Modal";
import { courtUtils } from "utils/court.utils";

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  max-height: 400px;
  min-width: 450px;
  padding-bottom: 0px;
  flex: 1;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const ModalTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 6px;
`;

const ModalFooter = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 40px;
`;

const InputLabel = styled.span`
  font-size: 14px;
  font-weight: 400;
`;

const CheckItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid #e0e0e0;
  padding-top: 6px;
  padding-bottom: 6px;
`;

const ReceiptBodyWithShadow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px;
  border-radius: 8px;
  width: 300px;
  align-self: center;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid #e0e0e0;
  margin-bottom: 16px;
`;

export const StopTabModal = ({
  isOpen,
  closeModal,
  courtTabId,
}: {
  isOpen: boolean;
  closeModal: () => void;
  courtTabId: StrapiId | undefined;
}) => {
  const dispatch = useAppDispatch();
  const courtTabs = useAppSelector((state) => state.courtTab.courtTabs);
  const priceConfig = useAppSelector((state) => state.priceConfig.config);
  const courtSchedules = useAppSelector(
    (state) => state.courtSchedule.courtSchedules
  );

  const openTabRacketSales = useAppSelector(
    (state) => state.racketLease.openTabRacketSales
  );
  const openTabinventorySale = useAppSelector(
    (state) => state.inventorySale.openTabinventorySale
  );
  const courtTab = courtTabs.find(
    (tab) => tab.id === courtTabId
  );

  const tabRacketSales = openTabRacketSales.filter(
    (racketLease) => racketLease.attributes.courtTab?.data?.id === courtTabId
  );
  const tabInventorySales = openTabinventorySale.filter(
    (inventorySale) =>
      inventorySale.attributes.courtTab?.data?.id === courtTabId
  );

  const onStopTab = async () => {
    const totalCost = courtUtils.findHourlyCost({
      courtTab,
      courtSchedules,
      priceConfig,
    });
    const totalHours = moment(courtTab?.attributes.startTime, "HH:mm").diff(
      moment(courtTab?.attributes.endTime, "HH:mm"),
      "hours"
    );
    await courtTabApi.update(courtTabId!, {
      status: CourtTabStatus.Finished,
      endTime: new Date(),
      total: totalCost,
      hours: totalHours,
    });
    dispatch(fetchCourtTabAsync());
    closeViewModal();
  };

  const closeViewModal = () => {
    closeModal();
  };

  const courtTabTime = useMemo(() => {
    return moment().diff(courtTab?.attributes.startTime, "minutes");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courtTab?.attributes.startTime, isOpen]);

  const totalCost = courtUtils.findHourlyCost({
    courtTab,
    courtSchedules,
    priceConfig,
  });

  const totalHours = Math.round(courtTabTime / 60);
  const totalMinutes = courtTabTime % 60;

  const totalPriceItems = courtUtils.getTotalPriceItems({
    inventorySales: tabInventorySales,
    racketLeases: tabRacketSales,
  });

  const granTotal = totalCost + totalPriceItems;
  return (
    <Modal isOpen={isOpen}>
      <>
        <ModalBody>
          <ModalTitle>Cerrar Cuenta</ModalTitle>

          <ReceiptBodyWithShadow>
            <CheckItem
              style={{
                borderBottom: "none",
                backgroundColor: "#f5f5f5",
                padding: 6,
                borderRadius: 4,
              }}
            >
              <InputLabel
                style={{
                  flex: 1,
                  fontWeight: 700,
                }}
              >
                Tiempo total:
              </InputLabel>
              <InputLabel style={{

              }}>
                {totalHours} Horas {totalMinutes} Minutos
              </InputLabel>
            </CheckItem>
            <Column>
              {tabRacketSales.map((racketLease) => (
                <CheckItem key={racketLease.id}>
                  <InputLabel
                    style={{
                      flex: 1,
                    }}
                  >
                    {racketLease.attributes.racket.data?.attributes.name}
                  </InputLabel>
                  <InputLabel>
                    {numeral(racketLease.attributes.price).format("$0,0.0")}
                  </InputLabel>
                </CheckItem>
              ))}
              {tabInventorySales.map((inventorySale) => (
                <CheckItem key={inventorySale.id}>
                  <InputLabel
                    style={{
                      flex: 1,
                    }}
                  >
                    {inventorySale.attributes.inventory.data?.attributes.name}
                  </InputLabel>
                  <InputLabel>
                    {numeral(inventorySale.attributes.price).format("$0,0.0")}
                  </InputLabel>
                </CheckItem>
              ))}
              <CheckItem>
                <InputLabel
                  style={{
                    flex: 1,
                  }}
                >
                  Costo Cancha:
                </InputLabel>
                <InputLabel>{numeral(totalCost).format("$0,0.0")}</InputLabel>
              </CheckItem>
              <CheckItem
                style={{
                  border: "none",
                }}
              >
                <InputLabel
                  style={{
                    flex: 1,
                    fontWeight: 700,
                  }}
                >
                  Costo Total:
                </InputLabel>
                <InputLabel
                  style={{
                    fontWeight: 700,
                  }}
                >
                  {numeral(granTotal).format("$0,0.0")}
                </InputLabel>
              </CheckItem>
            </Column>
          </ReceiptBodyWithShadow>
        </ModalBody>
        <ModalFooter>
          <Button $type="flat" onClick={closeViewModal}>
            Cancelar
          </Button>
          <Button
            $type="danger"
            type="submit"
            onClick={() => {
              onStopTab();
              
            }}
          >
            Cerrar cuenta
          </Button>
        </ModalFooter>
      </>
    </Modal>
  );
};
