import styled from "styled-components";
import { Line, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import "./dashboard.css";
import { CourtReservation, courtReservationApi } from "api/courtReservationApi";
import { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { StrapiEntries } from "api/strapi.types";
import { Button } from "ui/Button";
import {
  getTotalIncomeData,
  getTotalReservationData,
  getTotalReservationsHoursData,
  getTotalReservationsPerCourt,
  useTotals,
} from "./graphs.utils";
import numeral from "numeral";
import { Court, courtApi } from "api/courtApi";
import { useAppSelector } from "hooks/reduxHooks";
import { MetricCard } from "./components/MetricCard";
import { ActivityLoader } from "ui/ActivityLoader";
import { AdminRoles } from "api/adminApi";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const DashboardCointainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: white;
  flex-direction: column;
  height: 100%;
  border-radius: 8px;
  box-shadow: 0px 0px 2px 0 rgba(0, 0, 0, 0.1);
  padding: 12px;
`;

const TopBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const DateTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

export type DashboardData = {
  courtReservations?: StrapiEntries<CourtReservation>;
  courts?: StrapiEntries<Court>;
};

export const Dashboard = () => {
  const [dateRange, setDateRange] = useState<{
    startDate: Date;
    endDate: Date;
  }>({
    startDate: moment().startOf("month").toDate(),
    endDate: moment().endOf("month").toDate(),
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [graphData, setGraphData] = useState<DashboardData>({});
  const priceConfig = useAppSelector((state) => state.priceConfig.config);
  const [isAllowed, setIsAllowed] = useState<boolean | undefined>(undefined);
  const currentAdmin = useAppSelector(state => state.admin.currentAdmin);
  

  const onNextRange = () => {
    const date = moment(dateRange.startDate);
    date.add(1, "month");
    setDateRange({
      startDate: date.startOf("month").toDate(),
      endDate: date.endOf("month").toDate(),
    });
  };

  const onPreviousRange = () => {
    const date = moment(dateRange.startDate);
    date.subtract(1, "month");
    setDateRange({
      startDate: date.startOf("month").toDate(),
      endDate: date.endOf("month").toDate(),
    });
  };

  const getData = useCallback(async () => {
    setLoading(true);
    setGraphData({
      courtReservations: undefined,
    });
    try {
      const courtReservationData = courtReservationApi.getByDateRange(
        dateRange.startDate,
        dateRange.endDate
      );
      const courtData = courtApi.get();
      const [{ data: courtReservations }, { data: courts }] = await Promise.all(
        [courtReservationData, courtData]
      );
      setGraphData({
        courtReservations: courtReservations,
        courts,
      });
    } catch (e) {
      console.error("Error fetching data", e);
    }
    setLoading(false);
  }, [dateRange]);

  useEffect(() => {
    if(currentAdmin){
      setIsAllowed(currentAdmin.attributes.role === AdminRoles.Admin);
    }
    
  }, [currentAdmin]);

  useEffect(() => {
    if(isAllowed){
      getData();
    } 
  }, [getData, isAllowed]);

  const totalReservationsData = useMemo(() => {
    return getTotalReservationData(graphData, dateRange);
  }, [graphData, dateRange]);

  const totalReservationsHoursData = useMemo(() => {
    return getTotalReservationsHoursData(graphData, dateRange);
  }, [graphData, dateRange]);

  const totalIncomeData = useMemo(() => {
    return getTotalIncomeData(graphData, dateRange, priceConfig);
  }, [graphData, dateRange, priceConfig]);

  const totalReservationsPerCourt = useMemo(() => {
    return getTotalReservationsPerCourt(graphData);
  }, [graphData]);

  const totals = useTotals(graphData);

  return (
    <DashboardCointainer className="dashboard">
      <TopBar>
        <Button $type="flat" onClick={onPreviousRange}>
          Atras
        </Button>
        <DateTitle>
          {moment(dateRange.startDate).format("MMMM YYYY")}
          {loading && <ActivityLoader />}
        </DateTitle>
        <Button $type="flat" onClick={onNextRange}>
          Adelante
        </Button>
      </TopBar>
      <div className="metrics">
        <MetricCard
          title="Reservaciones"
          value={numeral(totals.totalReservations).format("0,0")}
        />
        <MetricCard
          title="Ingreso total(Sin considerar Prepaid)"
          value={numeral(totals.totalIncome).format("$0,0")}
        />
        <MetricCard
          title="Horas/Reservacion"
          value={numeral(totals.avgHoursPerReservation).format("0,0.00")}
        />
      </div>
      <div className="charts">
        <div className="chart">
          <Line data={totalReservationsData} />
        </div>
        <div className="chart">
          <Line data={totalReservationsHoursData} />
        </div>
        <div className="chart">
          <Line data={totalIncomeData} />
        </div>
        <div className="chart">
          <Bar data={totalReservationsPerCourt} />
        </div>

        {/* <div className="chart">
          <Bar data={barData} />
        </div>
        <div className="chart">
          <Pie data={pieData} />
        </div>
        <div className="chart">
          <Scatter data={scatterData} />
        </div>
        <div className="chart">
          <Bar data={barDataCancellations} />
        </div> */}
      </div>
    </DashboardCointainer>
  );
};
