import { Court } from "api/courtApi";
import { StrapiCollection, StrapiId } from "api/strapi.types";
import styled from "styled-components";
import { ColorPalette } from "ui/Color";
import {
  CourtScheduleType,
  CourtScheduleValidDays,
} from "api/courtScheduleApi";
import { useMemo } from "react";
import moment from "moment";
import { CourtReservation } from "api/courtReservationApi";

const DayOfWeekMap: { [key: number]: CourtScheduleValidDays } = {
  1: "monday",
  2: "tuesday",
  3: "wednesday",
  4: "thursday",
  5: "friday",
  6: "saturday",
  0: "sunday",
};

type CourtCardProps = {
  court: StrapiCollection<Court>;
  viewingDate: Date;
  courtReservations: StrapiCollection<CourtReservation>[];
  onTimeSlotSelected: ({
    timeSlot,
    reservation,
    courtId,
  }: {
    timeSlot: Date;
    reservation?: StrapiCollection<CourtReservation>;
    courtId: StrapiId;
  }) => void;
};

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${ColorPalette.lightGray};
  margin: 8px;
  padding: 12px;
  min-height: 200px;
  box-shadow: 6px 4px 16px 0px rgba(0, 0, 0, 0.11);
  -webkit-box-shadow: 6px 4px 16px 0px rgba(0, 0, 0, 0.11);
  -moz-box-shadow: 6px 4px 16px 0px rgba(0, 0, 0, 0.11);
`;

const CourtName = styled.span`
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 12px;
  border-bottom: 1px solid ${ColorPalette.lightGray};
  width: 100%;
  text-align: center;
`;

const AvailableSlots = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 6px;
  flex-wrap: wrap;
  margin-top: 12px;
`;

const TimeSlot = styled.div<{ $type: CourtScheduleType }>`
  padding: 6px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  min-height: 30px;
  font-size: 12px;
  background-color: ${({ $type }) => {
    switch ($type) {
      case CourtScheduleType.Regular:
        return ColorPalette.lightGray;
      case CourtScheduleType.Prime:
        return ColorPalette.limeGreen;
      case CourtScheduleType.Elite:
        return ColorPalette.green;
    }
  }};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const CourtCard = (props: CourtCardProps) => {
  const { court, viewingDate, courtReservations, onTimeSlotSelected } = props;

  const availableScheduleDates = useMemo(() => {
    const availableDates: {
      type: CourtScheduleType;
      date: Date;
      courtReservation?: StrapiCollection<CourtReservation>;
    }[] = [];
    court?.attributes.courtSchedules?.data?.forEach((courtSchedule) => {
      const startTime = moment(courtSchedule.attributes.startTime, "HH:mm");
      const endTime = moment(courtSchedule.attributes.endTime, "HH:mm");
      let currentDate = moment(viewingDate)
        .startOf("day")
        .add(startTime.hours(), "hours")
        .add(startTime.minutes(), "minutes")
        .toDate();
      let endDate = moment(viewingDate)
        .startOf("day")
        .add(endTime.hours(), "hours")
        .add(endTime.minutes(), "minutes")
        .toDate();

      const dayOfWeekProp = DayOfWeekMap[moment(viewingDate).day()];
      // @ts-ignore
      if (courtSchedule.attributes[dayOfWeekProp] === false) {
        return [];
      }

      while (currentDate <= endDate) {
        let _currentDate = currentDate;
        //If date slot already exists, skip
        if (
          availableDates.find(
            (date) => date.date.getTime() === _currentDate.getTime()
          )
        ) {
          continue;
        }

        //If date slot is already reserved, skip
        const foundReservation = courtReservations
          .filter((courtReservation) => {
            return courtReservation.attributes.court.data?.id === court.id;
          })
          .find(
            (reservation) =>
              moment(reservation.attributes.startTime).toDate() <=
                _currentDate &&
              moment(reservation.attributes.endTime).toDate() > _currentDate
          );

        availableDates.push({
          type: courtSchedule.attributes.type,
          date: currentDate,
          courtReservation: foundReservation,
        });
        currentDate = moment(_currentDate).add(1, "hour").toDate();
      }
    });
    return availableDates.sort((a, b) => a.date.getTime() - b.date.getTime());
  }, [
    court?.attributes.courtSchedules?.data,
    court.id,
    viewingDate,
    courtReservations,
  ]);

  return (
    <CardContainer>
      <CourtName>{court.attributes.name}</CourtName>

      <AvailableSlots>
        {availableScheduleDates.map((schedule) => {
          return (
            <TimeSlot
              $type={schedule.type}
              key={schedule.date.getTime()}
              onClick={() => {
                onTimeSlotSelected({
                  timeSlot: schedule.date,
                  reservation: schedule.courtReservation,
                  courtId: court.id,
                });
              }}
            >
              <span
                style={{
                  fontSize: 12,
                }}
              >
                {moment(schedule.date).format("hh:mm A")}
              </span>
              <strong>{schedule.courtReservation?.attributes.name}</strong>
            </TimeSlot>
          );
        })}
      </AvailableSlots>
    </CardContainer>
  );
};
