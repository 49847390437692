import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { PageTopSection } from "ui/PageTopSection";
import { PageTopSectionTitle } from "ui/PageTopSectionTitle";

const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 16px;
  padding: 16px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 1px 1px 2px 0 #0000000a;
`;


export const Courts = () => {
 
  return <MainContainer>
    <PageTopSection>
      <PageTopSectionTitle>Administracion de Canchas</PageTopSectionTitle>
    </PageTopSection>
    <BodyContainer>
      <Outlet />
    </BodyContainer>
  </MainContainer>;
};