import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { ClientRow } from "./ClientRow";
import { userApi } from "api/userApi";
import { useNavigate } from "react-router-dom";
import { Client, clientApi } from "api/clientApi";
import { StrapiCollection, StrapiId } from "api/strapi.types";
import _ from "lodash";

const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #fff;
  margin-top: 20px;
  border-radius: 8px;
`;

const TopHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px;
  padding-left: 24px;
  padding-right: 24px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
`;

const SearchInput = styled.input`
  padding: 12px;
  border: none;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
`;

const NoUsersView = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const ClientList = () => {
  const [clients, setClients] = useState<StrapiCollection<Client>[]>([]);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState<string>("");
  const fetchClients = useRef(_.debounce(async (searchText: string) => {
    console.log("fetching clients");
    if (searchText === "") {
      const { data: clients } = await clientApi.get();
      setClients(clients.data);
      return;
    }
    const { data: clients } = await clientApi.search(searchText);
    setClients(clients.data);
  }, 500)).current;
  useEffect(() => {
    fetchClients(searchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const onDelete = async (id: StrapiId) => {
    const clientUser = clients.find((user) => user.id === id);
    if (!window.confirm("¿Estás seguro que deseas eliminar este cliente?")) {
      return;
    }
    if (!clientUser) {
      alert("Cliente no encontrado");
      return;
    }

    await clientApi.delete(clientUser.id);

    if (clientUser.attributes.user.data?.id) {
      await userApi.deleteUser(clientUser.attributes.user.data?.id);
    }
    await fetchClients(searchText);
  };

  const onEdit = async (id: StrapiId) => {
    navigate(`/clients/${id}`);
  };

  const onClientSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    setSearchText(searchValue);
  };

  return (
    <MainContainer>
      <TopHeader>
        <span>Lista</span>
        <SearchInput placeholder="Buscar cliente" onChange={onClientSearch} />
      </TopHeader>

      {clients.length === 0 && (
        <NoUsersView>
          <h1>No hay clientes</h1>
        </NoUsersView>
      )}

      {clients.map((client) => {
        return (
          <ClientRow
            key={client.id}
            client={client}
            onDelete={onDelete}
            onEdit={onEdit}
          />
        );
      })}
    </MainContainer>
  );
};
