import moment from "moment";
import { backendClient } from "./backendClient";
import { Client } from "./clientApi";
import { Court } from "./courtApi";
import { StrapiDefaultAttributes, StrapiEntries, StrapiEntry, StrapiId, StrapiSingleRelation } from "./strapi.types";
import { CourtReservation } from "./courtReservationApi";


export enum CourtTabStatus {
  Started = "started",
  Finished = "finished",
  Paid = "paid",
  PendingPayment = "pendingPayment",
}
export type CreateCourtTabDto = {
  name: string;
  court: StrapiId;
  client: StrapiId;
  courtReservation?: StrapiId;
  startTime: Date;
  endTime?: Date;
  status?: CourtTabStatus;
  total?: number;
  hours?: number;
}


export interface CourtTab extends StrapiDefaultAttributes {
  name: string;
  court: StrapiSingleRelation<Court>;
  client: StrapiSingleRelation<Client>;
  courtReservation?: StrapiSingleRelation<CourtReservation>;
  hours?: number;
  total?: number;
  players?: number;
  startTime: Date;
  endTime?: Date;
}


export const courtTabApi = {
  create: (payload: CreateCourtTabDto) => {
    return backendClient.post("/api/court-tabs", {
      data : payload
    });
  },
  delete: (id: StrapiId) => {
    return backendClient.delete(`/api/court-tabs/${id}`);
  },
  get: () => {
    return backendClient.get<StrapiEntries<CourtReservation>>("/api/court-tabs?populate=*");
  },
  getByDate: (date: Date) => {
    const startOfDay = moment(date).startOf('day').toISOString();
    const endOfDay = moment(date).endOf('day').toISOString();
    return backendClient.get<StrapiEntries<CourtReservation>>(`/api/court-tabs?filters[startTime][$gte]=${startOfDay}&filters[startTime][$lte]=${endOfDay}&populate=*`);
  },
  getOpenTabs: () => {
    return backendClient.get<StrapiEntries<CourtReservation>>(`/api/court-tabs?filters[endTime][$null]=true&populate=*`);
  },
  getOne: (id: StrapiId) => {
    return backendClient.get<StrapiEntry<CourtReservation>>(`/api/court-tabs/${id}?populate=*`);
  },
  getByCourt: (courtId: StrapiId, date: Date) => {
    const startOfDay = moment(date).startOf('day').toISOString();
    return backendClient.get<StrapiEntries<CourtReservation>>(`/api/court-tabs/?filters[court][$eq]=${courtId}&filters[startTime][$gte]=${startOfDay}&populate=*`);
  },
  update: (id: StrapiId, payload: Partial<CreateCourtTabDto>) => {
    return backendClient.put(`/api/court-tabs/${id}`, {
      data: payload
    });
  }
}