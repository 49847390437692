import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  LoaderFunctionArgs,
  redirect,
  RouterProvider,
} from "react-router-dom";
import { Root } from "./routes/Root";
import { ErrorPage } from "./routes/ErrorPage";
import { store } from "./store";
import { Provider } from "react-redux";
import { Users } from "./routes/Users";
import { Login } from "routes/Login";
import { EditUser } from "routes/Users/EditUser";
import { Courts } from "routes/Courts";
import { CourtScheduleAssigment } from "routes/Courts/components/CourtScheduleAssigment";
import { CourtTabs } from "routes/Courts/CourtTabs";
import { CourtLease } from "routes/CourtLease/CourtLease";
import { Inventory } from "routes/Inventory/Inventory";
import { Rackets } from "routes/Rackets/Rackets";
import { userApi } from "api/userApi";
import { auth } from "auth/auth";
import { Clients } from "routes/Clients";
import { Reservations } from "routes/Reservations/Reservations";
import { EditClient } from "routes/Clients/EditClient";
import Dashboard from "routes/Dashboard";
import { adminApi } from "api/adminApi";

const protectedLoader = async ({ request }: LoaderFunctionArgs) => {
  try {
    const { data: user } = await userApi.me();
    const { data: admins } = await adminApi.searchAdminByUserId(user.id)
    return {
      user,
      admin: admins.data[0],
    };
  } catch (error) {
    console.warn("protectedLoader error", error);
    auth.logout();
    return redirect("/login");
  }
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    loader: protectedLoader,
    errorElement: <ErrorPage />,

    children: [
      {
        path: "/",
        element: <Dashboard />,
      },
      {
        path: "/reservations",
        element: <Reservations />,
      },
      {
        path: "/court-lease",
        element: <CourtLease />,
      },
      {
        path: "/inventory",
        element: <Inventory />,
      },
      {
        path: "/rackets",
        element: <Rackets />,
      },
      {
        path: "/users",
        element: <Users />,
      },
      {
        path: "/users/:userId",
        element: <EditUser />,
      },
      {
        path: "/clients",
        element: <Clients />,
      },
      {
        path: "/clients/:clientId",
        element: <EditClient />,
      },
      {
        path: "/courts",
        element: <Courts />,
        children: [
          {
            path: "/courts/:courtId/schedule",
            element: <CourtScheduleAssigment />,
          },
          {
            path: "/courts",
            element: <CourtTabs />,
          },
        ],
      },
    ],
  },
  {
    path: "login",
    loader: () => {
      if (auth.isAuthenticated()) {
        console.log("login redirect");
        //return redirect("/");
      }
      return null;
    },
    element: <Login />,
  },
]);


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
