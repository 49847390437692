import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import styled from "styled-components";
import { CourtCard } from "./CourtCard";
import { useEffect, useMemo, useState } from "react";
import { fetchCourtsAsync } from "store/court/courtSlice";
import { ColorPalette } from "ui/Color";
import { StrapiCollection, StrapiId } from "api/strapi.types";
import { CourtReservation, courtReservationApi } from "api/courtReservationApi";
import { Button } from "ui/Button";
import { ActivityLoader } from "ui/ActivityLoader";

import useModal from "hooks/useModal";
import { AddReservationModal } from "./AddReservationModal";
import { Court } from "api/courtApi";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${ColorPalette.white};
  border-radius: 8px;
  padding: 16px;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: column;
  gap: 20px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const AvailableSlotsPerCourt = ({
  viewingDate,
}: {
  viewingDate: Date;
}) => {
  const courts = useAppSelector((state) => state.court.courts);
  const [loading, setLoading] = useState<boolean>(false);
  const [courtReservations, setCourtReservations] = useState<
    StrapiCollection<CourtReservation>[]
  >([]);
  const [refresh, setRefresh] = useState<number>();
  const { isOpen, closeModal, openModal } = useModal();
  const [viewingReservation, setViewingReservation] =
    useState<StrapiCollection<CourtReservation>>();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<Date>();
  const [selectedCourtId, setSelectedCourtId] = useState<StrapiId>();

  const dispatch = useAppDispatch();

  const getDateReservation = async () => {
    setLoading(true);
    const { data } = await courtReservationApi.getByDate(viewingDate);
    setCourtReservations(data.data);

    setLoading(false);
  };

  const onTimeSlotSelected = ({
    timeSlot,
    reservation,
    courtId,
  }: {
    timeSlot: Date;
    reservation?: StrapiCollection<CourtReservation>;
    courtId: StrapiId;
  }) => {
    setViewingReservation(reservation);
    setSelectedTimeSlot(timeSlot);
    setSelectedCourtId(courtId);
    openModal();
  };

  const onRefresh = () => {
    setRefresh(Date.now());
  };

  useEffect(() => {
    dispatch(fetchCourtsAsync());
    getDateReservation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, viewingDate, refresh]);

  const manualOrder = useMemo(() => {
    const orderedCourts: StrapiCollection<Court>[] = [];
    if (courts.length === 0) {
      return orderedCourts;
    }
    // Manual ordering per client request to match physical location;
    // Replace this with sorting on the courts
    const courtOne = courts.find(
      (court) => court.attributes.name.trim() === "Cancha 1"
    );
    const courtTwo = courts.find(
      (court) => court.attributes.name.trim() === "Cancha 2"
    );
    const courtThree = courts.find(
      (court) => court.attributes.name.trim() === "Cancha 3"
    );
    const courtFour = courts.find(
      (court) => court.attributes.name.trim() === "Cancha 4"
    );

    if (courtTwo) {
      orderedCourts.push(courtTwo);
    }
    if (courtOne) {
      orderedCourts.push(courtOne);
    }
    if (courtFour) {
      orderedCourts.push(courtFour);
    }
    if (courtThree) {
      orderedCourts.push(courtThree);
    }

    // If there are any courts that are not in the manual order, add them to the end
    courts.forEach((court) => {
      orderedCourts.find((orderedCourt) => orderedCourt.id === court.id) ||
        orderedCourts.push(court);
    });

    return orderedCourts;
  }, [courts]);

  return (
    <MainContainer>
      <Row>
        <h2
          style={{
            alignSelf: "center",
            flex: 1,
            textAlign: "center",
          }}
        >
          Reservaciones
        </h2>
        {loading ? (
          <ActivityLoader />
        ) : (
          <Button onClick={onRefresh} $type="secondary">
            Refrescar
          </Button>
        )}
      </Row>

      <GridContainer>
        {manualOrder.map((court) => {
          return (
            <CourtCard
              onTimeSlotSelected={onTimeSlotSelected}
              viewingDate={viewingDate}
              courtReservations={courtReservations}
              key={court.id}
              court={court}
            />
          );
        })}
      </GridContainer>
      {selectedTimeSlot && selectedCourtId && (
        <AddReservationModal
          isOpen={isOpen}
          courtId={selectedCourtId}
          onClose={closeModal}
          onRefresh={onRefresh}
          editingReservation={viewingReservation}
          viewingDate={selectedTimeSlot}
        />
      )}
    </MainContainer>
  );
};
