import { userApi } from "api/userApi";
import { auth } from "auth/auth";
import { AxiosError } from "axios";
import { Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button } from "ui/Button";
import { InputWithLabel } from "ui/InputWithLabel";
import { PadelProLogo } from "ui/PadelPro";
import * as Yup from "yup";

const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #eff2f6;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
`;

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 1px 1px 2px 2px #0000000a;
  width: 300px;
  padding-bottom: 32px;
  padding-top: 32px;
`;

const LoginTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
`;

const loginValidationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email es requerido"),
  password: Yup.string().required("Contraseña es requerida"),
});

const ErrorLabel = styled.span`
  color: red;
  font-size: 12px;
  margin-top: 6px;
  margin-bottom: 6px;
`;

const Spacer = styled.div<{
  height?: string;
}>`
  height: ${(props) => props.height || "20px"};
  width: 100%;
`;

const LoginError = styled.span`
  color: red;
  font-size: 14px;
  margin-top: 6px;
`;

export const Login = () => {
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState<string>();

  const handleLogin = async ({ identifier, password} : { identifier: string; password: string }) => {
    try{
      const { data } = await userApi.login(identifier, password)
      auth.saveToken(data.jwt);
      navigate("/");
    }catch(e){
      console.log('Error on login', e);
      if(e instanceof AxiosError){
        setLoginError(e.response?.data?.error.message || 'Error desconocido, vuelve a intentarlo')
      } else {
        setLoginError('Error desconocido, vuelve a intentarlo');
      }
    }
  }
  return (
    <MainContainer>
      <LoginContainer>
        <LoginTitle>Iniciar Sesion</LoginTitle>
        <PadelProLogo />
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          onSubmit={(values) => {
            console.log('onSubmit', values);
            handleLogin({
              identifier: values.email,
              password: values.password
            }).catch((error) => {
              console.error('Error on login', error);
            });

          }}
          validationSchema={loginValidationSchema}
        >
          {({ errors, touched, handleBlur, handleChange, values, handleSubmit }) => {
            return (
              <>
                <InputWithLabel
                  name="email"
                  label="Correo"
                  width={"100%"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                {errors.email && touched.email ? (
                  <ErrorLabel>{errors.email}</ErrorLabel>
                ) : (
                  ""
                )}
                <InputWithLabel
                  name="password"
                  label="Contraseña"
                  width={"100%"}
                  type="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                />
                {errors.password && touched.password ? (
                  <ErrorLabel>{errors.password}</ErrorLabel>
                ) : (
                  ""
                )}
                <Spacer />
                <Button $type="primary" type="submit" width="100%" onClick={() => {
                  handleSubmit();
                }}>
                  Iniciar Sesion
                </Button>
              </>
            );
          }}
        </Formik>
        <LoginError>{loginError}</LoginError>
      </LoginContainer>
    </MainContainer>
  );
};
