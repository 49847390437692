import { User } from "api/userApi";

export const auth = {
  setUser: (user: any) => {
    localStorage.setItem("user", JSON.stringify(user));
  },
  getUser: (): User | undefined => {
    const userString = localStorage.getItem("user");
    return userString ? JSON.parse(userString) : undefined;
  },
  isAuthenticated: () => {
    return !!localStorage.getItem("jwtToken");
  },
  saveToken: (token: string) => {
    localStorage.setItem("jwtToken", token);
  },
  getToken: () => {
    return localStorage.getItem("jwtToken");
  },
  logout: () => {
    localStorage.removeItem("jwtToken");
  },
}