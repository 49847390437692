import { Inventory, inventoryApi } from "api/inventoryApi";
import { StrapiCollection } from "api/strapi.types";
import { Formik } from "formik";
import { useAppDispatch } from "hooks/reduxHooks";
import { fetchInventoriesAsync } from "store/inventory/inventorySlice";
import styled from "styled-components";
import { Button } from "ui/Button";
import { InputWithLabel } from "ui/InputWithLabel";
import Modal from "ui/Modal";
import * as Yup from "yup";

const createUserValidationSchema = Yup.object().shape({
  name: Yup.string().required("El nombre es requerido"),
  description: Yup.string().required("La descripcion es requerida"),
  price: Yup.number().required("El precio es requerido"),
  quantity: Yup.number().required("La cantidad es requerida")
});

const ModalFooter = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 20px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ModalTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 6px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const ErrorLabel = styled.span`
  color: red;
  font-size: 12px;
  margin-top: 6px;
  margin-bottom: 6px;
`;

export const AddInventoryModal = ({
  isOpen,
  onClose,
  editingInventory
}: {
  isOpen: boolean;
  onClose: () => void;
  editingInventory?: StrapiCollection<Inventory>;
}) => {
  const dispatch = useAppDispatch();
  const saveInventory = async ({
    name,
    description,
    price,
    quantity,
  }: {
    name: string;
    description: string;
    price: number;
    quantity: number;
  }) => {

    const payload = {
      name,
      description,
      price,
      qty: quantity,
    }
    if(editingInventory) {
      await inventoryApi.update(editingInventory.id, payload);
    } else {
      await inventoryApi.create(payload);
    }

    dispatch(fetchInventoriesAsync());
    onClose();
  };

  return (
    <Modal isOpen={isOpen}>
      <Formik
        initialValues={{
          name: editingInventory?.attributes.name ?? "",
          description: editingInventory?.attributes.description ?? "",
          price: editingInventory?.attributes.price ?? 0,
          quantity: editingInventory?.attributes.qty ?? 0,
        }}
        validationSchema={createUserValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          console.log("onSubmit", values);
          saveInventory({
            name: values.name,
            description: values.description,
            price: values.price,
            quantity: values.quantity,
          }).then(() => {
            setSubmitting(false);
          }).catch((error) => {
            alert("Error al guardar el inventario");
            console.error("Error saving inventory", error);
            setSubmitting(false)
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <>
            <ModalBody>
              <ModalTitle>Crear Inventario</ModalTitle>
              <Column>
                <InputWithLabel
                  label="Nombre"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
                {errors.name && touched.name && (
                  <ErrorLabel>{errors.name}</ErrorLabel>
                )}
                <InputWithLabel
                  label="Descripcion"
                  name="description"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                />
                {errors.description && touched.description && (
                  <ErrorLabel>{errors.description}</ErrorLabel>
                )}
                <InputWithLabel
                  label="Precio"
                  name="price"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.price}
                />
                {errors.price && touched.price && (
                  <ErrorLabel>{errors.price}</ErrorLabel>
                )}
                <InputWithLabel
                  label="Cantidad"
                  name="quantity"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.quantity}
                />
                {errors.quantity && touched.quantity && (
                  <ErrorLabel>{errors.quantity}</ErrorLabel>
                )}
               
              </Column>
            </ModalBody>
            <ModalFooter>
              <Button $type="flat" onClick={onClose}>
                Cancelar
              </Button>
              <Button
                type="submit"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Guardar
              </Button>
            </ModalFooter>
          </>
        )}
      </Formik>
    </Modal>
  );
};
