import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { StrapiCollection, StrapiId } from 'api/strapi.types';
import { CourtEvent, courtEventApi } from 'api/courtEventApi';


type InitialState = {
  courtEvents: StrapiCollection<CourtEvent>[];
  courtEventsByCourt: StrapiCollection<CourtEvent>[];
  loading: boolean;
  error: string | undefined;
};

// Define the initial state
const initialState: InitialState = {
  courtEvents: [],
  courtEventsByCourt: [],
  loading: false,
  error: undefined,
};

// Define the async thunk to fetch court events
export const fetchCourtEventsAsync = createAsyncThunk(
  'courts/fetchCourtEvents',
  async () => {
    try {
      const response = await courtEventApi.get();
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch court events');
    }
  }
);
// Define the async thunk to fetch court events for a court
export const fetchCourtEventsByCourtAsync = createAsyncThunk(
  'courts/fetchCourtEventsByCourt',
  async (courtId: StrapiId) => {
    try {
      const response = await courtEventApi.getByCourtId(courtId);
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch court events by court');
    }
  }
);

const courtEventSlice = createSlice({
  name: 'courtSchedule',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCourtEventsByCourtAsync.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(fetchCourtEventsByCourtAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.courtEventsByCourt = action.payload.data;
      })
      .addCase(fetchCourtEventsByCourtAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchCourtEventsAsync.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(fetchCourtEventsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.courtEvents = action.payload.data;
      })
      .addCase(fetchCourtEventsAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default courtEventSlice;