import { CourtReservation } from "api/courtReservationApi";
import { StrapiCollection, StrapiEntries } from "api/strapi.types";
import { DashboardData } from "./Dashboard";
import { useMemo } from "react";
import { PriceConfig } from "api/priceConfigApi";
import { useAppSelector } from "hooks/reduxHooks";
import { courtUtils } from "utils/court.utils";
import moment from "moment";

export const useTotals = (data: DashboardData) => {
  const priceConfig = useAppSelector((state) => state.priceConfig.config);


  const totalIncome = useMemo(() => {
    const total = data.courtReservations?.data.reduce((acc, reservation) => {
      const courtSchedule = data.courts?.data.find(court => court.id === reservation.attributes.court.data?.id)?.attributes.courtSchedules;


      const reservationCost = courtUtils.findReservationCost({
        startTime: reservation.attributes.startTime,
        endTime: reservation.attributes.endTime,
        courtSchedules: courtSchedule?.data || [],
        priceConfig,
      })
      return acc + (reservationCost || 0)
    }, 0) || 0;
    return total;
  }, [data.courtReservations, priceConfig, data.courts]);

  const avgHoursPerReservation = useMemo(() => {
    let avgHours = 0;
    data.courtReservations?.data.forEach((reservation) => {
      const totalHours = Math.ceil(moment(reservation.attributes.endTime).diff(
        new Date(reservation.attributes.startTime),
        "minutes"
      ) / 60);
      avgHours += totalHours;
    })
    return avgHours / (data.courtReservations?.data.length || 1);
  }, [data.courtReservations]);




  return {
    totalReservations: data.courtReservations?.data.length || 0,
    totalIncome,
    avgHoursPerReservation,
  }
}

export const getReservationByDate = (reservations: StrapiEntries<CourtReservation> | undefined, date: Date) => {
  if (!reservations) return 0;
  return reservations.data.filter(reservation => {
    return moment(reservation.attributes.startTime).isSame(date, 'day');
  }).length;
}

export const getReservationsHoursByDate = (reservations: StrapiEntries<CourtReservation> | undefined, date: Date) => {
  if (!reservations) return 0;
  return reservations.data.reduce((acc, reservation) => {
    if (moment(reservation.attributes.startTime).isSame(date, 'day')) {
      const totalHours = Math.ceil(moment(reservation.attributes.endTime).diff(
        new Date(reservation.attributes.startTime),
        "minutes"
      ) / 60);
      return acc + totalHours;
    }
    return acc;
  }, 0);
}

export const getTotalReservationData = (graphData: DashboardData, dateRange: { startDate: Date, endDate: Date }) => {
  const dates = Array.from(
    {
      length: moment(dateRange.endDate).diff(dateRange.startDate, "days") + 1,
    },
    (_, i) => moment(dateRange.startDate).add(i, "days")
  );
  const labels = dates.map((date) => date.format("ddd DD MMM"));
  const data = dates.map((date) => {
    return getReservationByDate(graphData.courtReservations, moment(date, 'YYYY-MM-DD').toDate());
  });

  return {
    labels: labels,
    datasets: [
      {
        label: "Total de reservaciones",
        data: data,
        fill: false,
        backgroundColor: "rgba(0,128,0,0.4)",
        borderColor: "rgba(0,128,0,1)",
      },
    ],
  };
};

export const getTotalReservationsHoursData = (graphData: DashboardData, dateRange: { startDate: Date, endDate: Date }) => {
  const dates = Array.from(
    {
      length: moment(dateRange.endDate).diff(dateRange.startDate, "days") + 1,
    },
    (_, i) => moment(dateRange.startDate).add(i, "days")
  );
  const labels = dates.map((date) => date.format("ddd DD MMM"));
  const data = dates.map((date) => {
    return getReservationsHoursByDate(graphData.courtReservations, moment(date, 'YYYY-MM-DD').toDate());
  });

  return {
    labels: labels,
    datasets: [
      {
        label: "Total de Horas/Dia",
        data: data,
        fill: true,
        backgroundColor: "rgba(0, 0, 255, 0.4)",
        borderColor: "rgba(0, 0, 255, 1)",
      },
    ],
  };
};


export const getTotalIncomeData = (graphData: DashboardData, dateRange: { startDate: Date, endDate: Date }, priceConfig: StrapiCollection<PriceConfig> | undefined) => {
  const dates = Array.from(
    {
      length: moment(dateRange.endDate).diff(dateRange.startDate, "days") + 1,
    },
    (_, i) => moment(dateRange.startDate).add(i, "days")
  );
  const labels = dates.map((date) => date.format("ddd DD MMM"));
  const data = dates.map((date) => {

    return graphData.courtReservations?.data.reduce((acc, reservation) => {
      const reservationStartTime = moment(reservation.attributes.startTime);
      if (!reservationStartTime.isBetween(moment(date).startOf("day"), moment(date).endOf("day"), 'hour', '[]')) {
        return acc;
      }
      const courtSchedule = graphData.courts?.data.find(court => court.id === reservation.attributes.court.data?.id)?.attributes.courtSchedules?.data || [];

      const cost = courtUtils.findReservationCost({
        courtSchedules: courtSchedule,
        startTime: reservation.attributes.startTime,
        endTime: reservation.attributes.endTime,
        priceConfig: priceConfig,
      })
      return acc + cost;
    }, 0);

  });

  return {
    labels: labels,
    datasets: [
      {
        label: "Ingresos(Sin considerar Prepaid)",
        data: data,
        fill: false,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };
};

export const getTotalReservationsPerCourt = (graphData: DashboardData) => {

  const courtReservationsData = graphData.courts?.data.map(court => {
    const totalReservations = graphData.courtReservations?.data.filter(reservation => reservation.attributes.court.data?.id === court.id).length ?? 0;
    return {
      court,
      totalReservations,
    }
  })

  const sortedData = courtReservationsData?.sort((a, b) => {
    if (b.court.attributes.name > a.court.attributes.name) {
      return -1;
    } else if (b.court.attributes.name < a.court.attributes.name) {
      return 1;
    } else {
      return 0;
    }
  });
  const labels = sortedData?.map(data => data.court.attributes.name) || [];
  const data = sortedData?.map(data => data.totalReservations) || [];
  return {
    labels,
    datasets: [
      {
        label: "Reservaciones por Cancha",
        data,
        fill: false,
        backgroundColor: "rgba(255, 165, 0, 0.4)",
        borderColor: "rgba(255, 165, 0, 1)",
      },
    ],
  };

}